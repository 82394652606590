@import url(/assets/css/vendor/flaticon.min.css);
@import url(/assets/css/vendor/font-awesome.min.css);
@import url(/assets/css/vendor/ionicons.min.css);
@import url(/assets/css/vendor/material-design-iconic-font.min.css);
@import url(/assets/css/vendor/et-line.css);
@import url(https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400);
@-webkit-keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 940px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: table; }

.modal-video-inner {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  height: 100%; }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -35px;
  right: -35px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*=============================================
=            CSS Index            =
=============================================

    -> Helper CSS
    -> Default CSS
    -> Spacing
    -> Header
    -> Footer
    -> Sidebar
    -> Button
    -> Section title
    -> Backgrounds
    -> Scroll top
    -> Breadcrumb
    -> Pagination
    -> Preloader
    -> Hero slider
    -> Service grid slider
    -> Video cta
    -> Project slider
    -> Team
    -> Testimonial slider
    -> Service tab
    -> Fun fact
    -> Feature icon
    -> Project gallery
    -> Fun fact
    -> Blog
    -> About
    -> Service
    -> Project
    -> Contact
    -> 404
    -> Landing page
    
 
/*=====  End of CSS Index  ======*/
/*=============================================
=            Helper CSS            =
=============================================*/
.row-0 {
  margin-left: 0px;
  margin-right: 0px; }
  .row-0 > [class*="col"] {
    padding-left: 0px;
    padding-right: 0px; }

.row-1 {
  margin-left: -1px;
  margin-right: -1px; }
  .row-1 > [class*="col"] {
    padding-left: 1px;
    padding-right: 1px; }

.row-2 {
  margin-left: -2px;
  margin-right: -2px; }
  .row-2 > [class*="col"] {
    padding-left: 2px;
    padding-right: 2px; }

.row-3 {
  margin-left: -3px;
  margin-right: -3px; }
  .row-3 > [class*="col"] {
    padding-left: 3px;
    padding-right: 3px; }

.row-4 {
  margin-left: -4px;
  margin-right: -4px; }
  .row-4 > [class*="col"] {
    padding-left: 4px;
    padding-right: 4px; }

.row-5 {
  margin-left: -5px;
  margin-right: -5px; }
  .row-5 > [class*="col"] {
    padding-left: 5px;
    padding-right: 5px; }

.row-6 {
  margin-left: -6px;
  margin-right: -6px; }
  .row-6 > [class*="col"] {
    padding-left: 6px;
    padding-right: 6px; }

.row-7 {
  margin-left: -7px;
  margin-right: -7px; }
  .row-7 > [class*="col"] {
    padding-left: 7px;
    padding-right: 7px; }

.row-8 {
  margin-left: -8px;
  margin-right: -8px; }
  .row-8 > [class*="col"] {
    padding-left: 8px;
    padding-right: 8px; }

.row-9 {
  margin-left: -9px;
  margin-right: -9px; }
  .row-9 > [class*="col"] {
    padding-left: 9px;
    padding-right: 9px; }

.row-10 {
  margin-left: -10px;
  margin-right: -10px; }
  .row-10 > [class*="col"] {
    padding-left: 10px;
    padding-right: 10px; }

.row-11 {
  margin-left: -11px;
  margin-right: -11px; }
  .row-11 > [class*="col"] {
    padding-left: 11px;
    padding-right: 11px; }

.row-12 {
  margin-left: -12px;
  margin-right: -12px; }
  .row-12 > [class*="col"] {
    padding-left: 12px;
    padding-right: 12px; }

.row-13 {
  margin-left: -13px;
  margin-right: -13px; }
  .row-13 > [class*="col"] {
    padding-left: 13px;
    padding-right: 13px; }

.row-14 {
  margin-left: -14px;
  margin-right: -14px; }
  .row-14 > [class*="col"] {
    padding-left: 14px;
    padding-right: 14px; }

.row-15 {
  margin-left: -15px;
  margin-right: -15px; }
  .row-15 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px; }

.row-16 {
  margin-left: -16px;
  margin-right: -16px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-16 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-16 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-16 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-16 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-16 > [class*="col"] {
    padding-left: 16px;
    padding-right: 16px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-16 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-16 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-16 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-16 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-17 {
  margin-left: -17px;
  margin-right: -17px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-17 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-17 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-17 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-17 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-17 > [class*="col"] {
    padding-left: 17px;
    padding-right: 17px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-17 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-17 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-17 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-17 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-18 {
  margin-left: -18px;
  margin-right: -18px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-18 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-18 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-18 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-18 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-18 > [class*="col"] {
    padding-left: 18px;
    padding-right: 18px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-18 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-18 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-18 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-18 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-19 {
  margin-left: -19px;
  margin-right: -19px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-19 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-19 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-19 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-19 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-19 > [class*="col"] {
    padding-left: 19px;
    padding-right: 19px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-19 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-19 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-19 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-19 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-20 {
  margin-left: -20px;
  margin-right: -20px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-20 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-20 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-20 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-20 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-20 > [class*="col"] {
    padding-left: 20px;
    padding-right: 20px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-20 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-20 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-20 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-20 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-21 {
  margin-left: -21px;
  margin-right: -21px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-21 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-21 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-21 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-21 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-21 > [class*="col"] {
    padding-left: 21px;
    padding-right: 21px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-21 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-21 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-21 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-21 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-22 {
  margin-left: -22px;
  margin-right: -22px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-22 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-22 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-22 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-22 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-22 > [class*="col"] {
    padding-left: 22px;
    padding-right: 22px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-22 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-22 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-22 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-22 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-23 {
  margin-left: -23px;
  margin-right: -23px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-23 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-23 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-23 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-23 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-23 > [class*="col"] {
    padding-left: 23px;
    padding-right: 23px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-23 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-23 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-23 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-23 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-24 {
  margin-left: -24px;
  margin-right: -24px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-24 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-24 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-24 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-24 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-24 > [class*="col"] {
    padding-left: 24px;
    padding-right: 24px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-24 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-24 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-24 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-24 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-25 {
  margin-left: -25px;
  margin-right: -25px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-25 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-25 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-25 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-25 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-25 > [class*="col"] {
    padding-left: 25px;
    padding-right: 25px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-25 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-25 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-25 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-25 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-26 {
  margin-left: -26px;
  margin-right: -26px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-26 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-26 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-26 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-26 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-26 > [class*="col"] {
    padding-left: 26px;
    padding-right: 26px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-26 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-26 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-26 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-26 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-27 {
  margin-left: -27px;
  margin-right: -27px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-27 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-27 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-27 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-27 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-27 > [class*="col"] {
    padding-left: 27px;
    padding-right: 27px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-27 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-27 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-27 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-27 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-28 {
  margin-left: -28px;
  margin-right: -28px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-28 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-28 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-28 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-28 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-28 > [class*="col"] {
    padding-left: 28px;
    padding-right: 28px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-28 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-28 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-28 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-28 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-29 {
  margin-left: -29px;
  margin-right: -29px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-29 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-29 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-29 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-29 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-29 > [class*="col"] {
    padding-left: 29px;
    padding-right: 29px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-29 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-29 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-29 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-29 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-30 {
  margin-left: -30px;
  margin-right: -30px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-30 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-30 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-30 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-30 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-30 > [class*="col"] {
    padding-left: 30px;
    padding-right: 30px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-30 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-30 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-30 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-30 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-31 {
  margin-left: -31px;
  margin-right: -31px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-31 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-31 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-31 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-31 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-31 > [class*="col"] {
    padding-left: 31px;
    padding-right: 31px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-31 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-31 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-31 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-31 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-32 {
  margin-left: -32px;
  margin-right: -32px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-32 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-32 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-32 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-32 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-32 > [class*="col"] {
    padding-left: 32px;
    padding-right: 32px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-32 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-32 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-32 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-32 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-33 {
  margin-left: -33px;
  margin-right: -33px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-33 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-33 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-33 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-33 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-33 > [class*="col"] {
    padding-left: 33px;
    padding-right: 33px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-33 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-33 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-33 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-33 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-34 {
  margin-left: -34px;
  margin-right: -34px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-34 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-34 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-34 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-34 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-34 > [class*="col"] {
    padding-left: 34px;
    padding-right: 34px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-34 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-34 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-34 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-34 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-35 {
  margin-left: -35px;
  margin-right: -35px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-35 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-35 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-35 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-35 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-35 > [class*="col"] {
    padding-left: 35px;
    padding-right: 35px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-35 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-35 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-35 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-35 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-36 {
  margin-left: -36px;
  margin-right: -36px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-36 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-36 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-36 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-36 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-36 > [class*="col"] {
    padding-left: 36px;
    padding-right: 36px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-36 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-36 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-36 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-36 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-37 {
  margin-left: -37px;
  margin-right: -37px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-37 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-37 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-37 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-37 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-37 > [class*="col"] {
    padding-left: 37px;
    padding-right: 37px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-37 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-37 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-37 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-37 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-38 {
  margin-left: -38px;
  margin-right: -38px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-38 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-38 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-38 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-38 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-38 > [class*="col"] {
    padding-left: 38px;
    padding-right: 38px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-38 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-38 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-38 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-38 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-39 {
  margin-left: -39px;
  margin-right: -39px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-39 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-39 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-39 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-39 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-39 > [class*="col"] {
    padding-left: 39px;
    padding-right: 39px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-39 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-39 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-39 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-39 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-40 {
  margin-left: -40px;
  margin-right: -40px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-40 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-40 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-40 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-40 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-40 > [class*="col"] {
    padding-left: 40px;
    padding-right: 40px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-40 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-40 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-40 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-40 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-41 {
  margin-left: -41px;
  margin-right: -41px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-41 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-41 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-41 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-41 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-41 > [class*="col"] {
    padding-left: 41px;
    padding-right: 41px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-41 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-41 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-41 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-41 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-42 {
  margin-left: -42px;
  margin-right: -42px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-42 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-42 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-42 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-42 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-42 > [class*="col"] {
    padding-left: 42px;
    padding-right: 42px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-42 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-42 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-42 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-42 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-43 {
  margin-left: -43px;
  margin-right: -43px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-43 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-43 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-43 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-43 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-43 > [class*="col"] {
    padding-left: 43px;
    padding-right: 43px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-43 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-43 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-43 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-43 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-44 {
  margin-left: -44px;
  margin-right: -44px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-44 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-44 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-44 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-44 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-44 > [class*="col"] {
    padding-left: 44px;
    padding-right: 44px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-44 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-44 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-44 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-44 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-45 {
  margin-left: -45px;
  margin-right: -45px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-45 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-45 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-45 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-45 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-45 > [class*="col"] {
    padding-left: 45px;
    padding-right: 45px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-45 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-45 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-45 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-45 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-46 {
  margin-left: -46px;
  margin-right: -46px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-46 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-46 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-46 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-46 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-46 > [class*="col"] {
    padding-left: 46px;
    padding-right: 46px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-46 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-46 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-46 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-46 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-47 {
  margin-left: -47px;
  margin-right: -47px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-47 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-47 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-47 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-47 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-47 > [class*="col"] {
    padding-left: 47px;
    padding-right: 47px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-47 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-47 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-47 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-47 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-48 {
  margin-left: -48px;
  margin-right: -48px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-48 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-48 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-48 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-48 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-48 > [class*="col"] {
    padding-left: 48px;
    padding-right: 48px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-48 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-48 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-48 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-48 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-49 {
  margin-left: -49px;
  margin-right: -49px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-49 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-49 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-49 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-49 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-49 > [class*="col"] {
    padding-left: 49px;
    padding-right: 49px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-49 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-49 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-49 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-49 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-50 {
  margin-left: -50px;
  margin-right: -50px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-50 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-50 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-50 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-50 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-50 > [class*="col"] {
    padding-left: 50px;
    padding-right: 50px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-50 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-50 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-50 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-50 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-51 {
  margin-left: -51px;
  margin-right: -51px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-51 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-51 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-51 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-51 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-51 > [class*="col"] {
    padding-left: 51px;
    padding-right: 51px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-51 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-51 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-51 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-51 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-52 {
  margin-left: -52px;
  margin-right: -52px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-52 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-52 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-52 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-52 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-52 > [class*="col"] {
    padding-left: 52px;
    padding-right: 52px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-52 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-52 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-52 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-52 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-53 {
  margin-left: -53px;
  margin-right: -53px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-53 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-53 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-53 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-53 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-53 > [class*="col"] {
    padding-left: 53px;
    padding-right: 53px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-53 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-53 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-53 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-53 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-54 {
  margin-left: -54px;
  margin-right: -54px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-54 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-54 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-54 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-54 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-54 > [class*="col"] {
    padding-left: 54px;
    padding-right: 54px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-54 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-54 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-54 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-54 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-55 {
  margin-left: -55px;
  margin-right: -55px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-55 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-55 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-55 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-55 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-55 > [class*="col"] {
    padding-left: 55px;
    padding-right: 55px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-55 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-55 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-55 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-55 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-56 {
  margin-left: -56px;
  margin-right: -56px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-56 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-56 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-56 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-56 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-56 > [class*="col"] {
    padding-left: 56px;
    padding-right: 56px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-56 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-56 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-56 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-56 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-57 {
  margin-left: -57px;
  margin-right: -57px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-57 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-57 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-57 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-57 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-57 > [class*="col"] {
    padding-left: 57px;
    padding-right: 57px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-57 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-57 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-57 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-57 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-58 {
  margin-left: -58px;
  margin-right: -58px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-58 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-58 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-58 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-58 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-58 > [class*="col"] {
    padding-left: 58px;
    padding-right: 58px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-58 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-58 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-58 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-58 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-59 {
  margin-left: -59px;
  margin-right: -59px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-59 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-59 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-59 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-59 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-59 > [class*="col"] {
    padding-left: 59px;
    padding-right: 59px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-59 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-59 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-59 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-59 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-60 {
  margin-left: -60px;
  margin-right: -60px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-60 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-60 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-60 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-60 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-60 > [class*="col"] {
    padding-left: 60px;
    padding-right: 60px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-60 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-60 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-60 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-60 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-61 {
  margin-left: -61px;
  margin-right: -61px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-61 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-61 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-61 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-61 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-61 > [class*="col"] {
    padding-left: 61px;
    padding-right: 61px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-61 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-61 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-61 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-61 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-62 {
  margin-left: -62px;
  margin-right: -62px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-62 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-62 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-62 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-62 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-62 > [class*="col"] {
    padding-left: 62px;
    padding-right: 62px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-62 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-62 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-62 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-62 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-63 {
  margin-left: -63px;
  margin-right: -63px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-63 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-63 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-63 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-63 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-63 > [class*="col"] {
    padding-left: 63px;
    padding-right: 63px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-63 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-63 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-63 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-63 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-64 {
  margin-left: -64px;
  margin-right: -64px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-64 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-64 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-64 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-64 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-64 > [class*="col"] {
    padding-left: 64px;
    padding-right: 64px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-64 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-64 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-64 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-64 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-65 {
  margin-left: -65px;
  margin-right: -65px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-65 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-65 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-65 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-65 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-65 > [class*="col"] {
    padding-left: 65px;
    padding-right: 65px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-65 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-65 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-65 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-65 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-66 {
  margin-left: -66px;
  margin-right: -66px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-66 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-66 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-66 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-66 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-66 > [class*="col"] {
    padding-left: 66px;
    padding-right: 66px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-66 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-66 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-66 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-66 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-67 {
  margin-left: -67px;
  margin-right: -67px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-67 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-67 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-67 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-67 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-67 > [class*="col"] {
    padding-left: 67px;
    padding-right: 67px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-67 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-67 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-67 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-67 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-68 {
  margin-left: -68px;
  margin-right: -68px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-68 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-68 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-68 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-68 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-68 > [class*="col"] {
    padding-left: 68px;
    padding-right: 68px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-68 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-68 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-68 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-68 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-69 {
  margin-left: -69px;
  margin-right: -69px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-69 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-69 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-69 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-69 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-69 > [class*="col"] {
    padding-left: 69px;
    padding-right: 69px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-69 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-69 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-69 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-69 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-70 {
  margin-left: -70px;
  margin-right: -70px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-70 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-70 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-70 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-70 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-70 > [class*="col"] {
    padding-left: 70px;
    padding-right: 70px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-70 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-70 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-70 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-70 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-71 {
  margin-left: -71px;
  margin-right: -71px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-71 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-71 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-71 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-71 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-71 > [class*="col"] {
    padding-left: 71px;
    padding-right: 71px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-71 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-71 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-71 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-71 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-72 {
  margin-left: -72px;
  margin-right: -72px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-72 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-72 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-72 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-72 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-72 > [class*="col"] {
    padding-left: 72px;
    padding-right: 72px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-72 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-72 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-72 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-72 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-73 {
  margin-left: -73px;
  margin-right: -73px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-73 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-73 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-73 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-73 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-73 > [class*="col"] {
    padding-left: 73px;
    padding-right: 73px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-73 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-73 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-73 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-73 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-74 {
  margin-left: -74px;
  margin-right: -74px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-74 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-74 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-74 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-74 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-74 > [class*="col"] {
    padding-left: 74px;
    padding-right: 74px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-74 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-74 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-74 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-74 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-75 {
  margin-left: -75px;
  margin-right: -75px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-75 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-75 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-75 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-75 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-75 > [class*="col"] {
    padding-left: 75px;
    padding-right: 75px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-75 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-75 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-75 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-75 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-76 {
  margin-left: -76px;
  margin-right: -76px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-76 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-76 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-76 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-76 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-76 > [class*="col"] {
    padding-left: 76px;
    padding-right: 76px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-76 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-76 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-76 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-76 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-77 {
  margin-left: -77px;
  margin-right: -77px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-77 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-77 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-77 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-77 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-77 > [class*="col"] {
    padding-left: 77px;
    padding-right: 77px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-77 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-77 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-77 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-77 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-78 {
  margin-left: -78px;
  margin-right: -78px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-78 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-78 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-78 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-78 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-78 > [class*="col"] {
    padding-left: 78px;
    padding-right: 78px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-78 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-78 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-78 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-78 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-79 {
  margin-left: -79px;
  margin-right: -79px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-79 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-79 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-79 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-79 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-79 > [class*="col"] {
    padding-left: 79px;
    padding-right: 79px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-79 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-79 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-79 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-79 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

.row-80 {
  margin-left: -80px;
  margin-right: -80px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .row-80 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row-80 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row-80 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row-80 {
      margin-left: -15px;
      margin-right: -15px; } }
  .row-80 > [class*="col"] {
    padding-left: 80px;
    padding-right: 80px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .row-80 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row-80 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row-80 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 767px) {
      .row-80 > [class*="col"] {
        padding-left: 15px;
        padding-right: 15px; } }

/* no gutters */
.no-gutters {
  margin-left: 0;
  margin-right: 0; }
  .no-gutters > .col, .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
    margin: 0; }

/*=====  End of Helper CSS  ======*/
/*=============================================
=            Default CSS            =
=============================================*/
*, *::after, *::before {
  box-sizing: border-box; }

html, body {
  height: 100%; }

body {
  line-height: 1.66;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  visibility: visible;
  font-family: "Roboto", sans-serif;
  color: #1d1d25;
  position: relative;
  background-color: #ffffff; }
  body.no-overflow {
    overflow: hidden; }
  body.boxed {
    max-width: 1500px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.06);
    margin: 0 auto; }

h1, h2, h3, h4, h5, h6 {
  color: #1d1d25;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  margin-top: 0;
  line-height: 1.23; }

h1 {
  font-size: 39px; }

h2 {
  font-size: 36px; }

h3 {
  font-size: 22px; }

h4 {
  font-size: 16px; }

h5 {
  font-size: 14px; }

h6 {
  font-size: 9px; }

p:last-child {
  margin-bottom: 0; }

a, button {
  color: inherit;
  display: inline-block;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer; }

a, button, img, input, span {
  transition: all 0.1s ease 0s; }

*:focus {
  outline: none !important; }

a:focus {
  color: inherit;
  outline: none;
  text-decoration: none; }

a:hover {
  text-decoration: none; }

button, input[type="submit"] {
  cursor: pointer; }

ul {
  list-style: outside none none;
  margin: 0;
  padding: 0; }

/*-- Tab Content & Pane Fix --*/
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-right: 15px;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' height='10px' width='15px'%3E%3Ctext x='0' y='10' fill='black'%3E%E2%96%BE%3C/text%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 95% 50%; }

.slick-slide > div > div {
  vertical-align: middle; }

.border {
  border: 1px solid #eee !important; }

.border-top {
  border-top: 1px solid #eee !important; }

.border-right {
  border-right: 1px solid #eee !important; }

.border-bottom {
  border-bottom: 1px solid #eee !important; }

.border-left {
  border-left: 1px solid #eee !important; }

/* slider default style */
.ht-swiper-button-nav {
  position: absolute;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), visibility 0.3s linear 2s, opacity 0.3s linear 2s;
  background-image: none;
  text-align: center;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  outline: none !important;
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  font-weight: 500;
  color: #222;
  border: 2px solid rgba(34, 34, 34, 0.16);
  border-radius: 100%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.01);
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: 2;
  background: none; }
  .ht-swiper-button-nav:hover {
    background-color: #dc143c;
    border-color: #dc143c;
    color: #ffffff; }

.ht-swiper-button-prev {
  left: 0; }

.ht-swiper-button-next {
  right: 0; }

.swiper-pagination {
  position: static;
  display: block;
  max-width: 100%;
  text-align: center;
  width: auto;
  z-index: 7;
  line-height: 1; }
  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    cursor: pointer;
    margin: 0 5px; }
    .swiper-pagination-bullet-active {
      background-color: #222;
      cursor: default; }
  .swiper-pagination .swiper-pagination-bullet {
    vertical-align: bottom; }

input::-webkit-input-placeholder {
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

input:-ms-input-placeholder {
  -ms-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

input::placeholder {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

.vertical-navigation-home-wrapper {
  margin-left: 300px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
    .vertical-navigation-home-wrapper {
      margin-left: 0; } }

@-webkit-keyframes moveVertical {
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

@keyframes moveVertical {
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

/*=====  End of Default CSS  ======*/
/*=============================================
=            spacing            =
=============================================*/
.section-space--inner--50 {
  padding-top: 50px;
  padding-bottom: 50px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-space--inner--50 {
      padding-top: 50px;
      padding-bottom: 50px; } }
  @media only screen and (max-width: 767px) {
    .section-space--inner--50 {
      padding-top: 50px;
      padding-bottom: 50px; } }

.section-space--inner--60 {
  padding-top: 60px;
  padding-bottom: 60px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-space--inner--60 {
      padding-top: 40px;
      padding-bottom: 40px; } }
  @media only screen and (max-width: 767px) {
    .section-space--inner--60 {
      padding-top: 40px;
      padding-bottom: 40px; } }

.section-space--inner--80 {
  padding-top: 80px;
  padding-bottom: 80px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-space--inner--80 {
      padding-top: 60px;
      padding-bottom: 60px; } }
  @media only screen and (max-width: 767px) {
    .section-space--inner--80 {
      padding-top: 40px;
      padding-bottom: 40px; } }

.section-space--inner--100 {
  padding-top: 100px;
  padding-bottom: 100px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-space--inner--100 {
      padding-top: 80px;
      padding-bottom: 80px; } }
  @media only screen and (max-width: 767px) {
    .section-space--inner--100 {
      padding-top: 60px;
      padding-bottom: 60px; } }

.section-space--inner--120 {
  padding-top: 120px;
  padding-bottom: 120px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-space--inner--120 {
      padding-top: 80px;
      padding-bottom: 80px; } }
  @media only screen and (max-width: 767px) {
    .section-space--inner--120 {
      padding-top: 60px;
      padding-bottom: 60px; } }

.section-space--inner--140 {
  padding-top: 140px;
  padding-bottom: 140px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-space--inner--140 {
      padding-top: 100px;
      padding-bottom: 100px; } }
  @media only screen and (max-width: 767px) {
    .section-space--inner--140 {
      padding-top: 80px;
      padding-bottom: 80px; } }

.section-space--inner--190 {
  padding-top: 190px;
  padding-bottom: 190px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-space--inner--190 {
      padding-top: 120px;
      padding-bottom: 120px; } }
  @media only screen and (max-width: 767px) {
    .section-space--inner--190 {
      padding-top: 100px;
      padding-bottom: 100px; } }

.section-space--inner--top--30 {
  padding-top: 30px; }

.section-space--inner--top--120 {
  padding-top: 120px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-space--inner--top--120 {
      padding-top: 80px; } }
  @media only screen and (max-width: 767px) {
    .section-space--inner--top--120 {
      padding-top: 60px; } }

.section-space--inner--top--200 {
  padding-top: 200px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-space--inner--top--200 {
      padding-top: 80px; } }
  @media only screen and (max-width: 767px) {
    .section-space--inner--top--200 {
      padding-top: 60px; } }

.section-space--inner--bottom--120 {
  padding-bottom: 120px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-space--inner--bottom--120 {
      padding-bottom: 80px; } }
  @media only screen and (max-width: 767px) {
    .section-space--inner--bottom--120 {
      padding-bottom: 60px; } }

.section-space--inner--bottom--30 {
  padding-bottom: 30px; }

.section-space--inner--bottom--50 {
  padding-bottom: 50px; }

.section-space--inner--bottom--285 {
  padding-bottom: 285px; }
  @media only screen and (max-width: 767px) {
    .section-space--inner--bottom--285 {
      padding-bottom: 265px; } }

.section-space--inner--bottom--300 {
  padding-bottom: 300px; }

.section-space--inner--left--30 {
  padding-left: 30px; }

.section-space--top--10 {
  margin-top: 10px !important; }

.section-space--top--20 {
  margin-top: 20px !important; }

.section-space--top--30 {
  margin-top: 30px !important; }

.section-space--top--m30 {
  margin-top: -30px !important; }

.section-space--top--60 {
  margin-top: 60px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-space--top--60 {
      margin-top: 40px; } }
  @media only screen and (max-width: 767px) {
    .section-space--top--60 {
      margin-top: 40px; } }

.section-space--top--80 {
  margin-top: 80px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-space--top--80 {
      margin-top: 60px; } }
  @media only screen and (max-width: 767px) {
    .section-space--top--80 {
      margin-top: 40px; } }

.section-space--bottom--30 {
  margin-bottom: 30px !important; }

.section-space--bottom--20 {
  margin-bottom: 20px; }

.section-space--bottom--m30 {
  margin-bottom: -30px !important; }

.section-space--bottom--40 {
  margin-bottom: 40px !important; }

.section-space--bottom--m40 {
  margin-bottom: -40px !important; }

.section-space--bottom--50 {
  margin-bottom: 50px !important; }
  @media only screen and (max-width: 767px) {
    .section-space--bottom--50 {
      margin-bottom: 30px !important; } }

.section-space--bottom--60 {
  margin-bottom: 60px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-space--bottom--60 {
      margin-bottom: 40px; } }
  @media only screen and (max-width: 767px) {
    .section-space--bottom--60 {
      margin-bottom: 40px; } }

.section-space--bottom--80 {
  margin-bottom: 80px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-space--bottom--80 {
      margin-bottom: 60px; } }
  @media only screen and (max-width: 767px) {
    .section-space--bottom--80 {
      margin-bottom: 40px; } }

.section-space--bottom--120 {
  margin-bottom: 120px !important; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-space--bottom--120 {
      margin-bottom: 80px !important; } }
  @media only screen and (max-width: 767px) {
    .section-space--bottom--120 {
      margin-bottom: 60px !important; } }

/*=====  End of spacing  ======*/
/*=============================================
=            Header            =
=============================================*/
.header-area {
  background-color: #ffffff; }
  .header-area.header-sticky.is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    -webkit-animation: .95s ease-in-out 0s normal none 1 running fadeInDown;
            animation: .95s ease-in-out 0s normal none 1 running fadeInDown;
    z-index: 999;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1); }
    .header-area.header-sticky.is-sticky .header-top-bar {
      display: none; }
    .header-area.header-sticky.is-sticky .search-form {
      display: none; }
    .header-area.header-sticky.is-sticky .header-navigation {
      flex-basis: calc(100% - 400px); }
    .header-area.header-sticky.is-sticky .header-search {
      flex-basis: 180px; }
    .header-area.header-sticky.is-sticky .header-below {
      display: none; }
    .header-area.header-sticky.is-sticky.header-area--transparent .header-top-area {
      display: none; }
    .header-area.header-sticky.is-sticky.header-area--transparent .header-navigation-area--transparent-bg {
      background-color: #ffffff; }
    .header-area.header-sticky.is-sticky.header-area--transparent .header-navigation__nav--transparent nav > ul > li > a {
      color: #222; }
      .header-area.header-sticky.is-sticky.header-area--transparent .header-navigation__nav--transparent nav > ul > li > a:hover {
        color: #dc143c; }
    .header-area.header-sticky.is-sticky.header-area--transparent .header-navigation__icon--transparent .header-navigation__icon__search a {
      color: #222; }
      .header-area.header-sticky.is-sticky.header-area--transparent .header-navigation__icon--transparent .header-navigation__icon__search a:hover {
        color: #dc143c; }
    .header-area.header-sticky.is-sticky.header-area--transparent .header-navigation__icon--transparent .header-navigation__icon__cart a {
      color: #222; }
      .header-area.header-sticky.is-sticky.header-area--transparent .header-navigation__icon--transparent .header-navigation__icon__cart a:hover {
        color: #dc143c; }
    .header-area.header-sticky.is-sticky .header-top-bar-area {
      display: none; }
    .header-area.header-sticky.is-sticky .header-navigation-wrapper--style5 .header-navigation {
      flex-basis: auto; }
      .header-area.header-sticky.is-sticky .header-navigation-wrapper--style5 .header-navigation__nav nav > ul > li > a {
        padding: 35px 15px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .header-area.header-sticky.is-sticky .header-navigation-wrapper--style5 .header-right .button-wrap {
        display: none; } }
    .header-area.header-sticky.is-sticky .header-bottom-bar {
      display: none; }
  .header-area.header-sticky--default.is-sticky .header-info-area {
    display: none; }
    @media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
      .header-area.header-sticky--default.is-sticky .header-info-area {
        display: block; } }
  @media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
    .header-area__desktop {
      display: none; } }
  @media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
    .header-area__desktop--default {
      display: block; } }
  @media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
    .header-area__desktop--default .header-top-bar {
      display: none; } }
  .header-area__desktop--default .header-contact-info {
    display: flex;
    justify-content: flex-end; }
    @media only screen and (max-width: 767px) {
      .header-area__desktop--default .header-contact-info {
        display: none; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .header-area__desktop--default .header-info-wrapper {
      justify-content: space-between; } }
  @media only screen and (max-width: 767px) {
    .header-area__desktop--default .header-info-wrapper {
      justify-content: space-between; } }
  @media only screen and (max-width: 479px) {
    .header-area__desktop--default .header-info-wrapper .logo {
      flex-basis: 265px;
      padding-right: 0; } }
  @media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
    .header-area__desktop--default .header-navigation-area {
      display: none; } }
  .header-area__desktop--default .mobile-navigation-icon {
    display: none; }
    @media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
      .header-area__desktop--default .mobile-navigation-icon {
        display: block; } }
    .header-area__desktop--default .mobile-navigation-icon i {
      background-color: #222; }
      .header-area__desktop--default .mobile-navigation-icon i:before {
        background-color: #222; }
      .header-area__desktop--default .mobile-navigation-icon i:after {
        background-color: #222; }
  .header-area__desktop--default .header-info-single-item {
    margin-right: 60px; }
    .header-area__desktop--default .header-info-single-item:last-child {
      margin-right: 0; }
  @media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
    .header-area__desktop--default .header-info-area {
      padding: 0px 0; } }
  .header-area__mobile {
    display: none; }
    @media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
      .header-area__mobile {
        display: block; } }
    .header-area__mobile .logo {
      flex-basis: 0;
      padding-right: 0; }
  .header-area--absolute {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 999; }
    @media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
      .header-area--absolute {
        position: static; } }

.header-top-bar {
  background-color: #313131;
  color: #ddd;
  font-weight: 400;
  font-size: 14px;
  line-height: 1; }
  .header-top-bar--white {
    padding: 0;
    background-color: #ffffff;
    color: #1d1d25;
    border-bottom: 1px solid #eee; }

.topbar-menu li {
  display: inline-block;
  margin-right: 15px; }
  .topbar-menu li:last-child {
    margin-right: 0; }
  .topbar-menu li a {
    display: block; }
    .topbar-menu li a:hover {
      color: #dc143c; }

.top-bar-right-wrapper {
  text-align: right; }
  .top-bar-right-wrapper .text a {
    color: #05103B; }
    .top-bar-right-wrapper .text a:hover {
      color: #dc143c; }

.topbar-info {
  display: inline-block; }
  .topbar-info li {
    display: inline-block;
    margin-right: 30px; }
    .topbar-info li:last-child {
      margin-right: 0; }
    .topbar-info li a {
      display: block; }
      .topbar-info li a:hover {
        color: #dc143c; }
      .topbar-info li a i {
        margin-right: 5px;
        font-size: 18px;
        line-height: 1;
        vertical-align: middle; }

.language-change-wrapper {
  display: inline-block;
  color: #ddd;
  font-weight: 700;
  font-size: 14px;
  line-height: 1;
  margin-left: 30px;
  position: relative;
  cursor: pointer; }
  .language-change-wrapper a:hover {
    color: #dc143c; }
  .language-change-wrapper .language-flag {
    position: relative;
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    vertical-align: middle;
    overflow: hidden;
    margin-right: 9px; }
    .language-change-wrapper .language-flag img {
      position: absolute;
      top: -50%;
      left: -50%;
      width: 48px;
      height: 48px;
      max-width: 48px; }
  .language-change-wrapper .selected-lang i {
    font-size: 8px;
    vertical-align: middle; }
  .language-change-wrapper .language-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    left: auto;
    box-shadow: 0 0 37px rgba(0, 0, 0, 0.07);
    background-color: #ffffff;
    z-index: 9;
    min-width: 200px;
    text-align: left;
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
    .language-change-wrapper .language-dropdown li a {
      display: block;
      padding: 10px;
      color: #222;
      font-size: 13px; }
      .language-change-wrapper .language-dropdown li a:hover {
        background: #eee; }
      .language-change-wrapper .language-dropdown li a img {
        width: 18px;
        margin-right: 10px; }
  .language-change-wrapper:hover .language-dropdown {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); }

.header-navigation-wrapper {
  display: flex;
  align-items: center; }

.logo {
  flex-basis: 220px;
  padding-right: 30px; }
  .logo a img {
    max-width: 190px; }

.header-navigation {
  display: flex; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .header-navigation {
      flex-basis: calc(100% - 430px); } }
  .header-navigation__nav {
    padding: 0 20px;
    position: relative; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .header-navigation__nav {
        flex-basis: 100%; } }
    .header-navigation__nav nav > ul {
      display: flex;
      justify-content: center; }
      .header-navigation__nav nav > ul > li {
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
        .header-navigation__nav nav > ul > li > a {
          white-space: nowrap; }
          .header-navigation__nav nav > ul > li > a.active {
            color: #dc143c; }
            .header-navigation__nav nav > ul > li > a.active:before {
              width: 100%;
              left: 0;
              right: auto; }
        .header-navigation__nav nav > ul > li.has-children > a {
          position: relative; }
          .header-navigation__nav nav > ul > li.has-children > a:after {
            position: static;
            margin-left: 10px;
            font-family: Ionicons;
            content: '\f123';
            font-size: 8px;
            font-weight: 500;
            vertical-align: middle; }
        .header-navigation__nav nav > ul > li.has-children--multilevel-submenu {
          position: relative; }
        .header-navigation__nav nav > ul > li.has-children:hover .megamenu {
          -webkit-transform: translateY(0);
                  transform: translateY(0);
          visibility: visible;
          opacity: 1; }
          .header-navigation__nav nav > ul > li.has-children:hover .megamenu--home-variation__item {
            visibility: visible;
            opacity: 1;
            -webkit-transform: translateY(0);
                    transform: translateY(0); }
        .header-navigation__nav nav > ul > li.has-children:hover > .submenu {
          -webkit-transform: translateY(0);
                  transform: translateY(0);
          visibility: visible;
          opacity: 1; }
        .header-navigation__nav nav > ul > li > a {
          display: block;
          font-size: 13px;
          color: #222;
          line-height: 1.26;
          font-weight: 400;
          padding: 46px 16px;
          position: relative;
          transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
          @media only screen and (min-width: 1200px) and (max-width: 1499px) {
            .header-navigation__nav nav > ul > li > a {
              padding: 46px 13px; } }
          .header-navigation__nav nav > ul > li > a:before {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 0;
            content: "";
            height: 3px;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            background-color: #ffffff; }
        .header-navigation__nav nav > ul > li:hover > a, .header-navigation__nav nav > ul > li.active > a {
          color: #dc143c; }
          .header-navigation__nav nav > ul > li:hover > a:before, .header-navigation__nav nav > ul > li.active > a:before {
            width: 100%;
            left: 0;
            right: auto; }
  .header-navigation__icon {
    display: flex;
    color: #222; }
    .header-navigation__icon i {
      font-size: 20px; }
    .header-navigation__icon__search > a {
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
      .header-navigation__icon__search > a:hover {
        color: #dc143c; }
    .header-navigation__icon__cart {
      margin-left: 20px;
      position: relative; }
      .header-navigation__icon__cart > a {
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
        .header-navigation__icon__cart > a:hover {
          color: #dc143c; }
      .header-navigation__icon__cart:before {
        position: absolute;
        top: -5px;
        right: -10px;
        padding: 0 4px;
        width: 15px;
        height: 15px;
        border-radius: 100%;
        color: #222;
        background: #dc143c;
        content: attr(data-count);
        font-weight: 500;
        font-size: 10px;
        line-height: 15px;
        text-align: center; }
      .header-navigation__icon__cart .minicart-box {
        position: absolute;
        top: calc(100% + 15px);
        right: 0;
        z-index: 99999;
        visibility: hidden;
        overflow: auto;
        padding: 30px 20px 27px;
        max-height: 700px;
        width: 360px;
        border-bottom: 3px solid #dc143c;
        background-color: #fff;
        opacity: 0;
        box-shadow: 0 0 37px rgba(0, 0, 0, 0.07);
        -webkit-transform: translateY(30px);
                transform: translateY(30px);
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
        .header-navigation__icon__cart .minicart-box.active {
          -webkit-transform: translateY(0);
                  transform: translateY(0);
          visibility: visible;
          opacity: 1; }
    .header-navigation__icon--transparent .header-navigation__icon__search > a {
      color: #ffffff; }
      .header-navigation__icon--transparent .header-navigation__icon__search > a:hover {
        color: #dc143c; }
    .header-navigation__icon--transparent .header-navigation__icon__cart > a {
      color: #ffffff; }
      .header-navigation__icon--transparent .header-navigation__icon__cart > a:hover {
        color: #dc143c; }
  .header-navigation--header-default {
    flex-basis: 0;
    justify-content: center; }
    .header-navigation--header-default .header-navigation__nav {
      padding: 0; }
      .header-navigation--header-default .header-navigation__nav nav > ul > li {
        padding: 0 15px; }
        .header-navigation--header-default .header-navigation__nav nav > ul > li > a {
          padding: 25px 0;
          color: #000000; }

.social-links ul li {
  display: inline-block;
  margin-right: 20px; }
  .social-links ul li:last-child {
    margin-right: 0; }
  .social-links ul li a {
    color: rgba(34, 34, 34, 0.4);
    display: block; }
    .social-links ul li a:hover {
      color: #222; }

.social-links--white-topbar {
  margin-right: 30px; }
  .social-links--white-topbar ul li a {
    font-size: 16px;
    color: #ffffff;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
    .social-links--white-topbar ul li a:hover {
      color: #dc143c; }

.megamenu {
  position: absolute;
  top: 100%;
  left: 0;
  box-shadow: 0 10px 37px rgba(0, 0, 0, 0.07);
  border-bottom: 3px solid #dc143c;
  background-color: #ffffff;
  -webkit-transform: translateY(50px);
          transform: translateY(50px);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition-delay: 0.3s;
  transition-duration: 0.6s;
  visibility: hidden;
  opacity: 0;
  z-index: 9; }
  .megamenu--mega {
    min-width: 980px;
    width: 100%;
    padding: 50px 25px 35px;
    display: flex;
    justify-content: space-around; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px) {
      .megamenu--mega {
        min-width: 700px; } }
    .megamenu--mega > li {
      flex-basis: 22%; }
      .megamenu--mega > li .page-list-title {
        font-size: 16px;
        padding-bottom: 10px;
        margin-bottom: 20px;
        border-bottom: 1px solid #eee;
        letter-spacing: 1px; }
      .megamenu--mega > li > ul > li {
        position: relative;
        padding-left: 15px; }
        .megamenu--mega > li > ul > li:after {
          position: absolute;
          width: 5px;
          height: 5px;
          top: 50%;
          border-radius: 50%;
          left: 0;
          content: '';
          -webkit-transform: translateY(-50%);
                  transform: translateY(-50%);
          background-color: #d8d8d8; }
        .megamenu--mega > li > ul > li a {
          padding: 10px 0;
          color: #999;
          line-height: 1.2;
          transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
          .megamenu--mega > li > ul > li a:hover {
            color: #dc143c; }

.submenu {
  position: absolute;
  top: 100%;
  left: 0;
  box-shadow: 0 10px 37px rgba(0, 0, 0, 0.07);
  border-bottom: 3px solid #dc143c;
  background-color: #ffffff;
  -webkit-transform: translateY(50px);
          transform: translateY(50px);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition-delay: 0.3s;
  transition-duration: 0.6s;
  visibility: hidden;
  opacity: 0;
  min-width: 270px;
  padding: 25px 0;
  z-index: 9; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .submenu {
      left: -80px; } }
  .submenu li {
    position: relative; }
    .submenu li.active a {
      color: #dc143c; }
    .submenu li > a {
      display: block;
      padding: 11px 30px;
      color: #999; }
      .submenu li > a:hover, .submenu li > a.active {
        color: #dc143c; }
    .submenu li:hover > .submenu {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      visibility: visible;
      opacity: 1;
      z-index: 9; }
    .submenu li.has-children > a {
      position: relative;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
      .submenu li.has-children > a:after {
        position: absolute;
        right: 20px;
        top: 50%;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        font-family: Ionicons;
        content: '\f125';
        font-size: 8px;
        font-weight: 500;
        vertical-align: middle; }
  .submenu .submenu {
    top: 0;
    left: 100%;
    right: auto; }
    .submenu .submenu .submenu {
      left: auto;
      right: 100%;
      background: #ffffff; }
      .submenu .submenu .submenu .submenu {
        left: 100%;
        right: auto;
        background: #ffffff; }
        .submenu .submenu .submenu .submenu .submenu {
          left: auto;
          right: 100%;
          background: #ffffff; }

/* header info area */
.header-info-area {
  padding: 0px 0; }
  @media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
    .header-info-area {
      border-bottom: 1px solid #eee; } }

.header-info-wrapper {
  display: flex; }
  .header-info-wrapper .logo {
    width: 280px;
    flex-basis: 280px; }
  .header-info-wrapper .header-contact-info {
    width: calc(100% - 280px); }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .header-info-wrapper .header-contact-info {
        display: none; } }

.header-info-single-item {
  display: flex;
  justify-content: flex-end; }
  .header-info-single-item__icon {
    color: #dc143c;
    width: 50px;
    height: 50px;
    border: 1px solid #dc143c;
    border-radius: 50%;
    text-align: center;
    margin-right: 15px; }
    .header-info-single-item__icon i {
      line-height: 50px;
      font-size: 26px; }
  .header-info-single-item__title {
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 0;
    font-weight: 500; }
  .header-info-single-item__subtitle {
    font-size: 16px;
    color: #737373; }

/* mobile menu */
.mobile-menu-area {
  padding: 15px 0; }

.mobile-menu-content {
  display: flex;
  justify-content: flex-end; }
  .mobile-menu-content .social-links {
    padding: 0 15px; }
    @media only screen and (max-width: 575px) {
      .mobile-menu-content .social-links {
        display: none; } }
  .mobile-menu-content .mobile-navigation-icon {
    margin-left: 15px; }

.mobile-navigation-icon {
  width: 24px;
  height: 25px;
  position: relative;
  cursor: pointer;
  float: right; }
  .mobile-navigation-icon:hover i {
    background-color: #222; }
    .mobile-navigation-icon:hover i:before {
      width: 80%;
      background-color: #222; }
    .mobile-navigation-icon:hover i:after {
      background-color: #222;
      width: 60%; }
  .mobile-navigation-icon i {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 100%;
    height: 2px;
    background-color: rgba(34, 34, 34, 0.4);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
    .mobile-navigation-icon i:before {
      position: absolute;
      bottom: 8px;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: rgba(34, 34, 34, 0.4);
      content: "";
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
    .mobile-navigation-icon i:after {
      position: absolute;
      bottom: -8px;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: rgba(34, 34, 34, 0.4);
      content: "";
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  @media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
    .mobile-navigation-icon--mobile-off {
      display: none; } }
  .mobile-navigation-icon--desktop-off {
    display: none; }
    @media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
      .mobile-navigation-icon--desktop-off {
        display: block; } }

/* mobile menu overlay */
.header-wrapper--shadow {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.07); }

.header-wrapper__inner {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .header-wrapper__inner .logo {
    flex-shrink: 0; }
  .header-wrapper__inner .header-navigation {
    flex-grow: 1; }
    .header-wrapper__inner .header-navigation__nav {
      display: flex;
      justify-content: center;
      flex-basis: 100%; }
  .header-wrapper__inner .request-quote-button-wrapper {
    flex-shrink: 0; }

/* offcanvas mobile menu */
.header-mobile-navigation {
  padding: 20px 0; }
  .header-mobile-navigation .mobile-navigation .header-cart-icon a span {
    left: 50%; }

.offcanvas-widget-area {
  margin-bottom: 35px;
  margin-top: auto; }
  @media only screen and (max-width: 479px) {
    .offcanvas-widget-area {
      margin-bottom: 30px; } }

.off-canvas-contact-widget {
  margin-bottom: 20px; }
  .off-canvas-contact-widget .header-contact-info {
    flex-basis: 33.33%; }
    .off-canvas-contact-widget .header-contact-info__list li {
      display: inline-block;
      margin-right: 25px; }
      .off-canvas-contact-widget .header-contact-info__list li i {
        font-size: 14px;
        margin-right: 5px; }
      .off-canvas-contact-widget .header-contact-info__list li a {
        color: #666;
        font-weight: 400;
        line-height: 22px; }
        .off-canvas-contact-widget .header-contact-info__list li a:hover {
          color: #dc143c; }
      .off-canvas-contact-widget .header-contact-info__list li:last-child {
        margin-right: 0; }

.offcanvas-mobile-menu {
  position: fixed;
  right: 0;
  top: 0;
  width: 400px;
  max-width: 100%;
  height: 100vh;
  z-index: 9999;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  padding-left: 60px;
  transition: 0.6s; }
  .offcanvas-mobile-menu.active {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  .offcanvas-mobile-menu.inactive {
    -webkit-transform: translateX(calc(100% + 60px));
            transform: translateX(calc(100% + 60px)); }

.offcanvas-menu-close {
  position: absolute;
  left: 0;
  top: 0;
  background: #343538;
  z-index: 9;
  width: 60px;
  height: 60px;
  color: #fff;
  line-height: 60px;
  text-align: center;
  font-size: 30px; }
  @media only screen and (max-width: 479px) {
    .offcanvas-menu-close {
      width: 50px;
      height: 50px;
      line-height: 55px;
      left: 10px;
      font-size: 25px; } }
  .offcanvas-menu-close:hover, .offcanvas-menu-close:focus {
    color: #000000; }
  .offcanvas-menu-close i {
    transition: 0.3s;
    -webkit-transform: rotate(0);
            transform: rotate(0); }
  .offcanvas-menu-close:hover i {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg); }

.offcanvas-wrapper {
  overflow: auto;
  height: 100%;
  box-shadow: 0 0 87px 0 rgba(0, 0, 0, 0.09);
  background-color: #ffffff; }

.offcanvas-mobile-search-area {
  background-color: #e6e6e6;
  padding: 10px;
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 60px);
  z-index: 9;
  margin-left: 60px; }
  .offcanvas-mobile-search-area input {
    width: 100%;
    font-size: 16px;
    display: block;
    padding: 9px 25px;
    color: #222;
    background: #e6e6e6;
    border: none; }
    @media only screen and (max-width: 479px) {
      .offcanvas-mobile-search-area input {
        font-size: 14px;
        padding: 5px 15px; } }
  .offcanvas-mobile-search-area button {
    background: none;
    border: none;
    position: absolute;
    right: 20px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    color: #aaa;
    padding: 0; }
    .offcanvas-mobile-search-area button i {
      font-size: 18px;
      line-height: 40px; }

.offcanvas-inner-content {
  padding: 90px 35px 0;
  height: 100%;
  display: flex;
  flex-direction: column; }
  @media only screen and (max-width: 479px) {
    .offcanvas-inner-content {
      padding: 70px 25px 0; } }

.offcanvas-navigation {
  margin-bottom: 50px; }
  .offcanvas-navigation > ul > li.menu-item-has-children > .sub-menu {
    height: 0;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s; }
  .offcanvas-navigation > ul > li.menu-item-has-children.active > .sub-menu {
    height: 100%;
    visibility: visible;
    opacity: 1; }
  .offcanvas-navigation > ul > li > a {
    font-size: 15px;
    color: #111;
    font-weight: 600;
    line-height: 20px;
    padding: 10px 0; }
    .offcanvas-navigation > ul > li > a:hover {
      color: #dc143c; }
    @media only screen and (max-width: 479px) {
      .offcanvas-navigation > ul > li > a {
        font-size: 14px;
        line-height: 20px; } }
  .offcanvas-navigation ul.sub-menu {
    margin-left: 25px;
    transition: 0.3s; }
    .offcanvas-navigation ul.sub-menu > li > a {
      font-size: 13px;
      color: #111;
      font-weight: 400;
      line-height: 20px;
      padding: 10px 0; }
      .offcanvas-navigation ul.sub-menu > li > a:hover {
        color: #dc143c; }
      @media only screen and (max-width: 479px) {
        .offcanvas-navigation ul.sub-menu > li > a {
          font-size: 13px;
          line-height: 18px; } }
  .offcanvas-navigation ul li.menu-item-has-children {
    position: relative;
    display: block; }
    .offcanvas-navigation ul li.menu-item-has-children a {
      display: block; }
    .offcanvas-navigation ul li.menu-item-has-children.active > .menu-expand i:before {
      -webkit-transform: rotate(0);
              transform: rotate(0); }
    .offcanvas-navigation ul li.menu-item-has-children .menu-expand {
      position: absolute;
      right: auto;
      left: 95%;
      top: -5px;
      width: 30px;
      height: 50px;
      line-height: 50px;
      cursor: pointer;
      text-align: center; }
      .offcanvas-navigation ul li.menu-item-has-children .menu-expand i {
        display: block;
        margin-top: 25px;
        border-bottom: 1px solid;
        position: relative;
        width: 10px;
        transition: all 250ms ease-out; }
        .offcanvas-navigation ul li.menu-item-has-children .menu-expand i:before {
          width: 100%;
          content: "";
          border-bottom: 1px solid;
          display: block;
          position: absolute;
          top: 0;
          -webkit-transform: rotate(90deg);
                  transform: rotate(90deg); }

.off-canvas-widget-social a {
  margin: 0 10px;
  font-size: 14px; }
  .off-canvas-widget-social a:first-child {
    margin-left: 0; }
  @media only screen and (max-width: 479px) {
    .off-canvas-widget-social a {
      margin: 0 10px; } }
  .off-canvas-widget-social a:hover {
    color: #dc143c; }

/* offcanvas settings */
.offcanvas-settings .offcanvas-navigation > ul > li > a {
  font-size: 12px;
  font-weight: 400;
  padding: 5px 0; }

.offcanvas-settings .offcanvas-navigation > ul > li.menu-item-has-children .menu-expand {
  height: 30px;
  top: -15px;
  margin-top: 0; }

.offcanvas-settings .offcanvas-navigation ul.sub-menu > li > a {
  padding: 5px 0; }

/*=====  End of Header  ======*/
/*=============================================
=            footer            =
=============================================*/
@media only screen and (max-width: 767px) {
  .footer-content-wrapper {
    margin-bottom: 0; } }

.footer-logo {
  margin-bottom: 35px; }
  .footer-logo--style2 {
    margin-bottom: 170px; }
    @media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
      .footer-logo--style2 {
        margin-bottom: 50px; } }

.footer-desc {
  color: #c7c7c7;
  max-width: 370px;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  line-height: 2.2; }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-intro-wrapper {
    margin-bottom: 40px; } }

@media only screen and (max-width: 767px) {
  .footer-intro-wrapper {
    margin-bottom: 30px; } }

@media only screen and (max-width: 767px) {
  .footer-widget-wrapper {
    margin-bottom: -30px; } }

@media only screen and (max-width: 767px) {
  .footer-widget {
    margin-bottom: 30px; } }

.footer-widget__title {
  color: #fff;
  margin-bottom: 50px;
  font-size: 18px; }
  @media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
    .footer-widget__title {
      margin-bottom: 20px; } }
  .footer-widget__title--black {
    color: #222; }

.footer-widget__navigation {
  line-height: 1; }
  .footer-widget__navigation li {
    padding: 0;
    line-height: 1; }
    .footer-widget__navigation li:first-child {
      padding-top: 0; }
    .footer-widget__navigation li:last-child {
      padding-bottom: 0; }
    .footer-widget__navigation li a {
      font-size: 14px;
      color: #c7c7c7;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      line-height: 2;
      font-family: "Poppins", sans-serif;
      font-weight: 300; }
      .footer-widget__navigation li a:hover {
        color: #dc143c; }

.footer-widget__content {
  color: #c7c7c7;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  line-height: 1.7; }
  .footer-widget__content .contact-details li span {
    margin-right: 10px; }

.footer-copyright-wrapper {
  padding: 15px 0;
  color: #c7c7c7;
  border-top: 1px solid #4a4a4a;
  font-size: 12px; }

/*=====  End of footer ======*/
/*=============================================
=            Sidebar            =
=============================================*/
/*-- Sidebar --*/
.sidebar {
  margin-bottom: 50px;
  background-color: #f7f8f9;
  padding: 30px; }
  .sidebar.sidebar-two {
    padding: 0;
    background-color: transparent; }
  .sidebar:last-child {
    margin-bottom: 0; }

/*-- Sidebar Title --*/
.sidebar-title {
  margin-bottom: 30px;
  margin-top: -4px; }

/*-- Sidebar Search --*/
.sidebar-search form {
  display: flex;
  border: 1px solid #e5e6e7; }
  .sidebar-search form input {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: calc(100% - 40px);
    max-width: calc(100% - 40px);
    height: 40px;
    border: none;
    background-color: transparent;
    padding: 0 15px;
    color: #1d1d25; }
  .sidebar-search form button {
    max-width: 40px;
    flex: 1 0 40px;
    height: 40px;
    border: none;
    background-color: transparent;
    display: flex;
    justify-content: center;
    padding: 0; }
    .sidebar-search form button i {
      font-size: 20px; }
    .sidebar-search form button:hover {
      color: #dc143c; }

/*-- Sidebar List --*/
.sidebar-list li {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e5e6e7; }
  .sidebar-list li:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0 solid transparent; }
  .sidebar-list li a {
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center; }
    .sidebar-list li a img {
      width: 25px;
      margin-right: 15px; }
    .sidebar-list li a i {
      margin-right: 15px;
      font-size: 24px; }
    .sidebar-list li a:hover {
      color: #dc143c; }

/*-- Sidebar Blog --*/
.sidebar-blog {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e5e6e7; }
  .sidebar-blog:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0; }
  .sidebar-blog .image {
    max-width: 80px;
    flex: 1 0 80px; }
    .sidebar-blog .image img {
      width: 100%; }
  .sidebar-blog .content {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: calc(100% - 80px);
    max-width: calc(100% - 80px);
    padding-left: 15px; }
    .sidebar-blog .content h5 {
      font-size: 16px;
      line-height: 1.5; }
      .sidebar-blog .content h5 a:hover {
        color: #dc143c; }
    .sidebar-blog .content span {
      font-size: 13px;
      display: block;
      line-height: 18px; }

/*-- Sidebar Tags --*/
.sidebar-tag {
  display: flex;
  flex-wrap: wrap;
  margin: -5px; }
  .sidebar-tag li {
    padding: 5px; }
    .sidebar-tag li a {
      display: block;
      border: 1px solid #e5e6e7;
      padding: 3px 15px;
      font-size: 14px; }
      .sidebar-tag li a:hover {
        background-color: #dc143c;
        border-color: #dc143c;
        color: #ffffff; }

/*=====  End of Sidebar  ======*/
/*=============================================
=            Button            =
=============================================*/
.ht-btn--default {
  display: inline-block;
  font-size: 14px;
  line-height: 2;
  padding: 10px 40px;
  font-weight: 400;
  text-transform: uppercase;
  color: #000000;
  background-color: #dc143c;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .ht-btn--default:hover, .ht-btn--default:focus {
    background-color: #ad102f;
    color: #000000; }
  .ht-btn--default--dark-hover:hover, .ht-btn--default--dark-hover:focus {
    background-color: #222;
    color: #000000; }

.ht-btn--round {
  font-size: 13px;
  padding: 15px 50px;
  background-color: #dc143c;
  color: #000000;
  border-radius: 100px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .ht-btn--round:hover, .ht-btn--round:focus {
    background-color: #222;
    color: #ffffff; }

.see-more-link {
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  color: #393939;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  text-decoration: underline;
  background: none;
  border: none; }
  .see-more-link:hover {
    color: #dc143c;
    text-decoration: underline; }
  .see-more-link--color {
    color: #dc143c; }
    .see-more-link--color:hover {
      color: #393939; }

/*=====  End of Button  ======*/
/*=============================================
=            section title            =
=============================================*/
.section-title {
  display: inline-block;
  font-size: 36px;
  color: #dc143c;
  font-weight: 700;
  margin-top: -5px; }
  @media only screen and (max-width: 479px) {
    .section-title {
      font-size: 30px; } }

.section-subtitle {
  font-size: 18px;
  line-height: 1.5;
  color: #1d1d25;
  font-family: "Poppins", sans-serif; }

.title-icon {
  width: 4px;
  height: 20px;
  background-color: #dc143c;
  display: block;
  margin: 0 auto;
  margin-top: 15px; }

/*=====  End of section title  ======*/
/*=============================================
=            backgrounds            =
=============================================*/
/* background images */
.funfact-bg {
  background-image: url("/assets/img/backgrounds/funfact-bg.jpg"); }

.testimonial-slider-area-bg {
  background-image: url("/assets/img/backgrounds/testimonial.jpg"); }

.breadcrumb-bg {
  background-image: url("/assets/img/backgrounds/funfact-bg.jpg"); }

.white-bg {
  background-color: #ffffff; }

.grey-bg {
  background-color: #f7f7f7; }
  .grey-bg--style2 {
    background-color: #eeeeee; }
  .grey-bg--style3 {
    background-color: #f5f6fa; }

.dark-bg {
  background-color: #111; }
  .dark-bg--style2 {
    background-color: #11202D; }
  .dark-bg--style3 {
    background-color: #222; }
  .dark-bg--style4 {
    background-color: #06112C; }
  .dark-bg--style5 {
    background-color: #05103B; }

.default-bg {
  background-color: #dc143c; }

/*=====  End of backgrounds  ======*/
/*=============================================
=            scroll top            =
=============================================*/
button.scroll-top {
  position: fixed;
  right: 30px;
  bottom: -60px;
  z-index: 999;
  box-shadow: 0 30px 50px rgba(0, 0, 0, 0.03);
  display: block;
  padding: 0;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  text-align: center;
  font-size: 25px;
  line-height: 60px;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  background-color: #dc143c;
  color: #ffffff;
  transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
  border-color: transparent; }
  @media only screen and (max-width: 479px) {
    button.scroll-top {
      width: 50px;
      height: 50px;
      line-height: 50px;
      font-size: 20px; } }
  button.scroll-top.show {
    visibility: visible;
    opacity: 1;
    bottom: 60px; }

/*=====  End of scroll top  ======*/
/*=============================================
=            breadcrumb            =
=============================================*/
.breadcrumb-area {
  padding: 80px 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  z-index: 1; }
  .breadcrumb-area::before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-color: #000000;
    opacity: 0.75;
    z-index: -1; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .breadcrumb-area {
      padding: 60px 0; } }
  @media only screen and (max-width: 767px) {
    .breadcrumb-area {
      padding: 40px 0; } }
  @media only screen and (max-width: 575px) {
    .breadcrumb-area {
      padding: 25px 0; } }

/*-- Page Banner --*/
.page-banner h1 {
  font-size: 48px;
  color: #ffffff;
  font-weight: 500;
  letter-spacing: 0.1px;
  margin-top: -10px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .page-banner h1 {
      font-size: 36px;
      margin-top: -5px; } }
  @media only screen and (max-width: 767px) {
    .page-banner h1 {
      font-size: 30px;
      margin-top: -5px; } }
  @media only screen and (max-width: 575px) {
    .page-banner h1 {
      font-size: 24px; } }

/*-- Page Breadcrumb --*/
.page-breadcrumb {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }
  .page-breadcrumb li {
    color: #ffffff;
    font-size: 18px;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    letter-spacing: 0.5px;
    line-height: 1;
    margin-top: 10px; }
    @media only screen and (max-width: 575px) {
      .page-breadcrumb li {
        font-size: 16px; } }
    .page-breadcrumb li::after {
      content: "-";
      margin: 0 6px; }
    .page-breadcrumb li:last-child::after {
      display: none; }
    .page-breadcrumb li a:hover {
      color: #dc143c; }

/*=====  End of breadcrumb  ======*/
/*=============================================
=            pagination            =
=============================================*/
.page-pagination {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: -5px -10px; }
  .page-pagination li {
    font-size: 16px;
    line-height: 24px;
    color: #1d1d25;
    text-align: center;
    margin: 5px 10px; }
    .page-pagination li a {
      color: #1d1d25;
      background-color: #f8f8f8;
      padding: 10px;
      border-radius: 50px;
      width: 44px;
      height: 44px;
      display: flex;
      justify-content: center;
      align-items: center; }
      .page-pagination li a i {
        line-height: 24px; }
    @media only screen and (max-width: 575px) {
      .page-pagination li {
        font-size: 14px; }
        .page-pagination li a {
          padding: 9px;
          width: 40px;
          height: 40px; }
          .page-pagination li a i {
            line-height: 24px; } }
    .page-pagination li:hover a {
      color: #ffffff;
      background-color: #1d1d25; }
    .page-pagination li.active a {
      color: #ffffff;
      background-color: #dc143c; }
    .page-pagination li:first-child a {
      color: #1d1d25;
      width: auto;
      padding: 10px 20px; }
      .page-pagination li:first-child a i {
        margin-right: 10px;
        float: left; }
      .page-pagination li:first-child a:hover {
        color: #ffffff; }
    .page-pagination li:last-child a {
      color: #1d1d25;
      width: auto;
      padding: 10px 20px;
      flex-direction: row-reverse; }
      .page-pagination li:last-child a i {
        margin-left: 10px;
        float: right; }
      .page-pagination li:last-child a:hover {
        color: #ffffff; }

/*=====  End of pagination  ======*/
/*=============================================
=            preloader            =
=============================================*/
.preloader-active {
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  z-index: 99999;
  background-color: #ffffff; }
  .preloader-active .preloader-area-wrap {
    position: absolute;
    left: 50%;
    display: block;
    top: 50%;
    z-index: 999999;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
    .preloader-active .preloader-area-wrap .spinner {
      display: flex; }
      .preloader-active .preloader-area-wrap .spinner div {
        background-color: #dc143c;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        margin-right: 15px;
        -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
                animation: sk-bouncedelay 1.4s infinite ease-in-out both; }
        .preloader-active .preloader-area-wrap .spinner div.bounce1 {
          -webkit-animation-delay: -0.32s;
          animation-delay: -0.32s; }
        .preloader-active .preloader-area-wrap .spinner div.bounce2 {
          -webkit-animation-delay: -0.16s;
          animation-delay: -0.16s; }

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

.preloader-area-wrap {
  display: none; }

/*=====  End of preloader  ======*/
/*=============================================
=            hero slider            =
=============================================*/
.hero-alider-area .ht-swiper-button-nav {
  background-color: rgba(255, 255, 255, 0.3);
  border: 0;
  color: #ffffff; }
  .hero-alider-area .ht-swiper-button-nav:hover {
    background-color: #dc143c; }

.hero-alider-area .ht-swiper-button-prev {
  left: 100px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .hero-alider-area .ht-swiper-button-prev {
      left: 20px; } }

.hero-alider-area .ht-swiper-button-next {
  right: 100px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .hero-alider-area .ht-swiper-button-next {
      right: 20px; } }

.hero-alider-area .swiper-slide-active .hero-slider__content > *:nth-child(1) {
  -webkit-animation-name: fadeInDown;
          animation-name: fadeInDown;
  -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s; }

.hero-alider-area .swiper-slide-active .hero-slider__content > *:nth-child(2) {
  -webkit-animation-name: fadeInLeft;
          animation-name: fadeInLeft;
  -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s;
  -webkit-animation-delay: 1s;
          animation-delay: 1s; }

.hero-alider-area .swiper-slide-active .hero-slider__content > *:nth-child(3) {
  -webkit-animation-name: fadeInDown;
          animation-name: fadeInDown;
  -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s;
  -webkit-animation-delay: 2.5s;
          animation-delay: 2.5s; }

.hero-slider__single-item {
  width: 100vw !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom; }

.hero-slider__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 730px;
  width: 580px;
  max-width: 100%; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px), only screen and (min-width: 992px) and (max-width: 1199px) {
    .hero-slider__content {
      height: 600px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .hero-slider__content {
      height: 550px; } }
  @media only screen and (max-width: 767px) {
    .hero-slider__content {
      height: 450px; } }
  .hero-slider__content > * {
    -webkit-animation-name: fadeInUp;
            animation-name: fadeInUp;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both; }

.hero-slider__title {
  font-size: 70px;
  line-height: 1.2;
  color: #ffffff;
  margin-bottom: 40px;
  margin-top: -15px; }
  @media only screen and (min-width: 1200px) and (max-width: 1499px) {
    .hero-slider__title {
      font-size: 60px;
      margin-top: -12px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .hero-slider__title {
      font-size: 60px;
      margin-top: -12px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .hero-slider__title {
      font-size: 50px;
      margin-top: -8px; } }
  @media only screen and (max-width: 767px) {
    .hero-slider__title {
      font-size: 40px;
      margin-top: -8px;
      margin-bottom: 20px; } }
  @media only screen and (max-width: 575px) {
    .hero-slider__title {
      font-size: 35px;
      margin-bottom: 30px; } }

.hero-slider__text {
  font-size: 16px;
  line-height: 1.8;
  color: #ffffff;
  margin-bottom: 50px; }
  @media only screen and (max-width: 767px) {
    .hero-slider__text {
      margin-bottom: 30px; } }
  @media only screen and (max-width: 575px) {
    .hero-slider__text {
      margin-bottom: 40px; } }

.hero-slider__btn {
  font-size: 13px;
  line-height: 1;
  padding: 20px 60px;
  color: #ffffff;
  border: 1px solid #ffffff;
  align-self: flex-start;
  border-radius: 50px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hero-slider__btn--style2 {
    align-self: center; }
  .hero-slider__btn:hover, .hero-slider__btn:focus, .hero-slider__btn:active {
    color: #000000;
    background-color: #dc143c;
    border-color: #dc143c; }

/*=====  End of hero slider  ======*/
/*=============================================
=            service grid slider            =
=============================================*/
.service-slider .ht-swiper-button-nav {
  display: none; }

.service-slider__container--style2 {
  margin-top: -245px; }
  .service-slider__container--style2 .swiper-container {
    padding: 10px;
    margin: -10px; }

.service-grid-item__image {
  position: relative;
  margin-bottom: 60px; }
  .service-grid-item__image .icon {
    width: 70px;
    height: 70px;
    line-height: 70px;
    text-align: center;
    background-color: #393939;
    position: absolute;
    bottom: -35px;
    left: 20px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
    .service-grid-item__image .icon i {
      color: #ffffff;
      font-size: 40px; }
  .service-grid-item__image a {
    display: block; }
    .service-grid-item__image a img {
      width: 100%;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition-duration: 0.6s; }

.service-grid-item__image-wrapper {
  overflow: hidden; }

.service-grid-item__content {
  line-height: 1; }
  .service-grid-item__content .title a {
    font-size: 22px;
    color: #393939;
    font-weight: 700;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
    .service-grid-item__content .title a:hover {
      color: #dc143c; }
    @media only screen and (max-width: 479px) {
      .service-grid-item__content .title a {
        font-size: 22px; } }
  .service-grid-item__content .subtitle {
    font-size: 16px;
    line-height: 1.8;
    margin-bottom: 15px;
    font-family: "Poppins", sans-serif;
    color: #5a5a5a; }

.service-grid-item:hover .service-grid-item__image .icon {
  background-color: #dc143c; }

.service-grid-item:hover .service-grid-item__image img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1); }

.service-grid-item:hover .service-grid-item__content .see-more-link {
  color: #dc143c; }

.service-grid-item--style2 {
  box-shadow: 0 1px 15.36px 0.64px rgba(0, 0, 0, 0.2); }
  .service-grid-item--style2 .service-grid-item__image {
    margin-bottom: 0; }
  .service-grid-item--style2 .service-grid-item__content {
    background-color: #ffffff;
    padding: 30px; }

/*=====  End of service grid slider  ======*/
/*=============================================
=            video cta            =
=============================================*/
.video-cta-content__small-title {
  margin-top: -5px;
  font-size: 15px;
  font-weight: 700;
  line-height: 1.5;
  color: #dc143c;
  margin-bottom: 15px; }

.video-cta-content__title {
  font-size: 36px;
  line-height: 1.2;
  color: #1d1d25;
  width: 415px;
  max-width: 100%;
  margin-bottom: 30px; }
  @media only screen and (max-width: 767px) {
    .video-cta-content__title {
      font-size: 30px; } }

.video-cta-content__text {
  font-size: 14px;
  line-height: 1.8;
  color: #1d1d25;
  font-family: "Poppins", sans-serif;
  margin-bottom: 40px;
  width: 500px;
  max-width: 100%; }
  @media only screen and (max-width: 767px) {
    .video-cta-content__text {
      margin-bottom: 20px; } }
  @media only screen and (max-width: 479px) {
    .video-cta-content__text {
      font-size: 16px; } }

@media only screen and (max-width: 767px) {
  .video-cta-content {
    margin-bottom: 30px; } }

.cta-video-image {
  position: relative;
  margin-right: 20px;
  margin-bottom: 20px; }
  .cta-video-image a {
    display: block; }
  .cta-video-image__image {
    overflow: hidden; }
    .cta-video-image__image img {
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition-duration: 0.6s;
      width: 100%; }
  .cta-video-image__icon {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #dc143c;
    position: absolute;
    line-height: 100px;
    text-align: center;
    top: 50%;
    -webkit-transform: translateY(-50%) scale(1);
            transform: translateY(-50%) scale(1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition-duration: 0.6s;
    left: -50px; }
    @media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
      .cta-video-image__icon {
        width: 70px;
        height: 70px;
        line-height: 70px;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%); } }
    .cta-video-image__icon i {
      font-size: 36px;
      color: #ffffff; }
  .cta-video-image:hover .cta-video-image__image img {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  .cta-video-image:hover .cta-video-image__icon {
    -webkit-transform: translateY(-50%) scale(1.1);
            transform: translateY(-50%) scale(1.1); }
    @media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
      .cta-video-image:hover .cta-video-image__icon {
        -webkit-transform: translate(-50%, -50%) scale(1.1);
                transform: translate(-50%, -50%) scale(1.1); } }
  .cta-video-image:after {
    content: "";
    width: 100%;
    height: 100%;
    border-right: 5px solid #dc143c;
    border-bottom: 5px solid #dc143c;
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: -1; }
  .cta-video-image .video-popup > button {
    background: none;
    border: none;
    width: 100%; }

/*=====  End of video cta  ======*/
/*=============================================
=            project slider            =
=============================================*/
.latest-project-slider__container-area {
  position: relative; }
  .latest-project-slider__container-area .ht-swiper-button-prev {
    left: -100px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
      .latest-project-slider__container-area .ht-swiper-button-prev {
        left: 30px; } }
    @media only screen and (max-width: 479px) {
      .latest-project-slider__container-area .ht-swiper-button-prev {
        left: 15px; } }
  .latest-project-slider__container-area .ht-swiper-button-next {
    right: -100px; }
    @media only screen and (min-width: 1200px) and (max-width: 1499px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
      .latest-project-slider__container-area .ht-swiper-button-next {
        right: 30px; } }
    @media only screen and (max-width: 479px) {
      .latest-project-slider__container-area .ht-swiper-button-next {
        right: 15px; } }

.latest-project-slider__single-slide .image img {
  width: 100%; }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .latest-project-slider__single-slide .image {
    margin-bottom: 50px; } }

@media only screen and (max-width: 767px) {
  .latest-project-slider__single-slide .image {
    margin-bottom: 30px; } }

.latest-project-slider__single-slide .content .count {
  font-size: 60px;
  line-height: 1;
  color: #bebebe;
  font-weight: 700;
  margin-bottom: 20px; }
  @media only screen and (max-width: 767px) {
    .latest-project-slider__single-slide .content .count {
      font-size: 40px; } }

.latest-project-slider__single-slide .content .title {
  font-size: 30px;
  font-weight: 700;
  color: #393939;
  margin-bottom: 20px; }
  @media only screen and (max-width: 767px) {
    .latest-project-slider__single-slide .content .title {
      font-size: 25px; } }

.latest-project-slider__single-slide .content .desc {
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  margin-bottom: 30px; }
  @media only screen and (max-width: 479px) {
    .latest-project-slider__single-slide .content .desc {
      font-size: 16px; } }

/*=====  End of project slider  ======*/
/*=============================================
=            team            =
=============================================*/
.team-job__title {
  margin-bottom: 30px;
  margin-top: -5px;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .team-job__title span {
    line-height: 1;
    vertical-align: top;
    display: inline-block;
    padding-bottom: 10px; }
  @media only screen and (max-width: 767px) {
    .team-job__title {
      font-size: 30px; } }
  @media only screen and (max-width: 479px) {
    .team-job__title {
      font-size: 25px; } }

.team-job__title-wrapper {
  padding-bottom: 40px;
  margin-bottom: 40px;
  border-bottom: 1px solid #cecece; }

.team-job__content {
  line-height: 1; }

.team-job__list-wrapper {
  margin-bottom: -40px; }

.team-job__single {
  margin-bottom: 40px;
  border-left: 2px solid #1d1d25;
  padding-left: 15px; }
  .team-job__single .title {
    font-size: 20px;
    margin-bottom: 15px; }
    .team-job__single .title a {
      color: #1d1d25;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
      .team-job__single .title a:hover {
        color: #dc143c; }
  .team-job__single .text {
    font-size: 16px;
    color: #757575; }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team-job__image {
    margin-top: 50px; } }

@media only screen and (max-width: 767px) {
  .team-job__image {
    margin-top: 30px; } }

.team-member-wrapper {
  margin-bottom: -30px; }

/*-- Team --*/
.team {
  border: 1px solid #eeeeee;
  transition: all 0.3s ease 0s; }
  .team .image img {
    width: 100%; }
  .team .content {
    text-align: center;
    padding: 25px 20px 30px; }
    .team .content .title {
      font-size: 24px;
      margin-bottom: 10px; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .team .content .title {
          font-size: 20px; } }
    .team .content span {
      display: block;
      line-height: 18px;
      margin-bottom: 5px;
      font-family: "Poppins", sans-serif; }
    .team .content .email {
      display: block;
      line-height: 18px;
      margin-bottom: 5px;
      font-family: "Poppins", sans-serif; }
      .team .content .email:hover {
        color: #dc143c; }
    .team .content .social {
      display: flex;
      flex-wrap: wrap;
      justify-content: center; }
      .team .content .social a {
        width: 30px;
        height: 30px;
        padding: 5px;
        text-align: center;
        border-radius: 50px;
        display: block;
        border: 1px solid #dc143c;
        color: #dc143c;
        margin-top: 10px;
        margin-right: 10px; }
        .team .content .social a:last-child {
          margin-right: 0; }
        .team .content .social a:hover {
          color: #ffffff !important;
          background-color: #dc143c; }
          .team .content .social a:hover.facebook {
            background-color: #4867AA; }
          .team .content .social a:hover.twitter {
            background-color: #1DA1F2; }
          .team .content .social a:hover.linkedin {
            background-color: #007BB6; }
          .team .content .social a:hover.google {
            background-color: #DD5144; }
          .team .content .social a:hover.instagram {
            background-color: #B23A94; }
          .team .content .social a:hover.pinterest {
            background-color: #BD081B; }
          .team .content .social a:hover.skype {
            background-color: #00A9F0; }
          .team .content .social a:hover.tumblr {
            background-color: #36465D; }
        .team .content .social a i {
          display: block;
          font-size: 14px;
          line-height: 18px; }
        .team .content .social a.facebook {
          border-color: #4867AA;
          color: #4867AA; }
        .team .content .social a.twitter {
          border-color: #1DA1F2;
          color: #1DA1F2; }
        .team .content .social a.linkedin {
          border-color: #007BB6;
          color: #007BB6; }
        .team .content .social a.google {
          border-color: #DD5144;
          color: #DD5144; }
        .team .content .social a.instagram {
          border-color: #B23A94;
          color: #B23A94; }
        .team .content .social a.pinterest {
          border-color: #BD081B;
          color: #BD081B; }
        .team .content .social a.skype {
          border-color: #00A9F0;
          color: #00A9F0; }
        .team .content .social a.tumblr {
          border-color: #36465D;
          color: #36465D; }
  .team:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
    border-color: transparent; }

/*=====  End of team  ======*/
/*=============================================
=            testimonial slider            =
=============================================*/
.testimonial-slider-area-bg {
  background-size: cover;
  background-repeat: no-repeat; }

.testimonial-slider__container-area {
  position: relative; }
  .testimonial-slider__container-area .swiper-pagination-bullets {
    position: absolute;
    right: 0;
    left: auto !important;
    top: 50%;
    bottom: auto !important;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: auto !important;
    display: inline-block; }
    @media only screen and (max-width: 767px) {
      .testimonial-slider__container-area .swiper-pagination-bullets {
        position: static;
        -webkit-transform: none;
                transform: none;
        margin-top: 30px;
        display: block; } }
  .testimonial-slider__container-area .swiper-pagination-bullet {
    display: block;
    margin: 0 !important;
    margin-bottom: 10px !important;
    background-color: #ffffff;
    opacity: 1; }
    .testimonial-slider__container-area .swiper-pagination-bullet:last-child {
      margin-bottom: 0 !important; }
      @media only screen and (max-width: 767px) {
        .testimonial-slider__container-area .swiper-pagination-bullet:last-child {
          margin-right: 0 !important; } }
    @media only screen and (max-width: 767px) {
      .testimonial-slider__container-area .swiper-pagination-bullet {
        display: inline-block;
        margin-bottom: 0 !important;
        margin-right: 10px !important; } }
    .testimonial-slider__container-area .swiper-pagination-bullet:last-child {
      margin-bottom: 0; }
    .testimonial-slider__container-area .swiper-pagination-bullet-active {
      background-color: #dc143c; }

.testimonial-slider__single-slide {
  color: #ffffff;
  opacity: 0 !important; }
  .testimonial-slider__single-slide.swiper-slide-active {
    opacity: 1 !important; }
  .testimonial-slider__single-slide .author {
    display: flex;
    align-items: center;
    margin-bottom: 20px; }
    .testimonial-slider__single-slide .author__image {
      flex-basis: 100px;
      margin-right: 30px;
      width: 100px;
      height: 100px; }
      .testimonial-slider__single-slide .author__image img {
        border-radius: 50%; }
    .testimonial-slider__single-slide .author__details {
      flex-basis: calc(100% - 100px); }
      .testimonial-slider__single-slide .author__details .name {
        font-size: 18px;
        color: #ffffff;
        font-weight: 400; }
      .testimonial-slider__single-slide .author__details .designation {
        font-size: 16px;
        font-family: "Poppins", sans-serif; }
  .testimonial-slider__single-slide .content {
    font-size: 24px;
    line-height: 1.5;
    font-style: italic;
    font-weight: 300;
    width: 800px;
    max-width: 100%; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .testimonial-slider__single-slide .content {
        width: 640px;
        margin-bottom: -5px; } }
    @media only screen and (max-width: 767px) {
      .testimonial-slider__single-slide .content {
        font-size: 20px; } }
    @media only screen and (max-width: 479px) {
      .testimonial-slider__single-slide .content {
        font-size: 18px; } }

/*=====  End of testimonial slider  ======*/
/*=============================================
=            service tab            =
=============================================*/
@media only screen and (max-width: 767px) {
  .service-tab__link-wrapper {
    margin-bottom: 40px; } }

.service-tab__link-wrapper.nav-tabs {
  border-bottom: 0; }

.service-tab__link-wrapper .nav-link, .service-tab__link-wrapper .react-tabs__tab {
  position: relative;
  padding: 30px 20px;
  line-height: 1;
  background-color: #ffffff;
  box-shadow: 0 1px 15.36px 0.64px rgba(0, 0, 0, 0.15);
  margin-bottom: 15px;
  font-size: 24px;
  font-weight: 700;
  border: 0;
  display: flex;
  align-items: center;
  cursor: pointer; }
  @media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
    .service-tab__link-wrapper .nav-link, .service-tab__link-wrapper .react-tabs__tab {
      font-size: 20px; } }
  @media only screen and (max-width: 767px) {
    .service-tab__link-wrapper .nav-link, .service-tab__link-wrapper .react-tabs__tab {
      font-size: 18px;
      padding: 20px; } }
  .service-tab__link-wrapper .nav-link:last-child, .service-tab__link-wrapper .react-tabs__tab:last-child {
    margin-bottom: 0; }
  .service-tab__link-wrapper .nav-link i, .service-tab__link-wrapper .react-tabs__tab i {
    vertical-align: middle;
    font-size: 40px;
    margin-right: 10px; }
  .service-tab__link-wrapper .nav-link:after, .service-tab__link-wrapper .react-tabs__tab:after {
    left: 100%;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    margin-left: -15px;
    z-index: -1;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(255, 194, 70, 0);
    border-left-color: #dc143c;
    border-width: 15px;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition-duration: 0.2s; }
    @media only screen and (max-width: 767px) {
      .service-tab__link-wrapper .nav-link:after, .service-tab__link-wrapper .react-tabs__tab:after {
        display: none; } }
  .service-tab__link-wrapper .nav-link:hover, .service-tab__link-wrapper .nav-link.active, .service-tab__link-wrapper .nav-link.react-tabs__tab--selected, .service-tab__link-wrapper .react-tabs__tab:hover, .service-tab__link-wrapper .react-tabs__tab.active, .service-tab__link-wrapper .react-tabs__tab.react-tabs__tab--selected {
    background-color: #dc143c;
    color: #ffffff;
    border-radius: 0; }
    .service-tab__link-wrapper .nav-link:hover:after, .service-tab__link-wrapper .nav-link.active:after, .service-tab__link-wrapper .nav-link.react-tabs__tab--selected:after, .service-tab__link-wrapper .react-tabs__tab:hover:after, .service-tab__link-wrapper .react-tabs__tab.active:after, .service-tab__link-wrapper .react-tabs__tab.react-tabs__tab--selected:after {
      visibility: visible;
      opacity: 1;
      margin-left: 0; }
  .service-tab__link-wrapper .nav-link button, .service-tab__link-wrapper .react-tabs__tab button {
    background: none;
    border: none; }

.service-tab__single-content-wrapper {
  padding: 40px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }
  @media only screen and (max-width: 479px) {
    .service-tab__single-content-wrapper {
      padding: 40px 15px; } }

.service-tab__single-content {
  color: #ffffff;
  padding: 40px;
  position: relative;
  z-index: 1;
  width: 580px;
  max-width: 100%; }
  @media only screen and (max-width: 479px) {
    .service-tab__single-content {
      padding: 40px 15px; } }
  .service-tab__single-content .see-more-link {
    color: #ffffff; }
    .service-tab__single-content .see-more-link:hover {
      color: #dc143c; }
  .service-tab__single-content:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000;
    opacity: 0.6;
    content: "";
    z-index: -1; }

.service-tab__title {
  color: #ffffff;
  font-size: 30px;
  margin-bottom: 20px; }
  @media only screen and (max-width: 479px) {
    .service-tab__title {
      font-size: 25px; } }

.service-tab__text {
  margin-bottom: 30px;
  width: 415px;
  max-width: 100%;
  font-size: 16px;
  line-height: 1.8; }

.service-tab-wrapper .react-tabs__tab-panel {
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  margin-left: 40px; }
  .service-tab-wrapper .react-tabs__tab-panel--selected {
    opacity: 1;
    height: 100%; }
    @media only screen and (max-width: 767px) {
      .service-tab-wrapper .react-tabs__tab-panel--selected {
        margin-left: 0; } }

/*=====  End of service tab  ======*/
/*=============================================
=            fun fact            =
=============================================*/
.fun-fact-wrapper {
  margin-bottom: -30px; }

.funfact-section {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  z-index: 1; }
  .funfact-section::before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-color: #000000;
    opacity: 0.75;
    z-index: -1; }

/*-- Funfact --*/
.single-fact {
  text-align: center; }
  .single-fact img {
    margin-bottom: 20px; }
  .single-fact .counter {
    font-size: 50px;
    font-weight: 700;
    color: #ffffff;
    line-height: 1;
    margin-bottom: 15px; }
  .single-fact h4 {
    line-height: 1;
    margin: 0;
    color: #ffffff; }

/*=====  End of fun fact  ======*/
/*=============================================
=            feature icon            =
=============================================*/
.feature-icon-wrapper {
  margin-bottom: -30px; }

.single-feature-icon {
  margin-bottom: 30px; }
  .single-feature-icon__image {
    margin-bottom: 20px; }
  .single-feature-icon__title {
    font-size: 24px;
    line-height: 1.3;
    color: #393939;
    margin-bottom: 15px; }
  .single-feature-icon__content {
    color: #5a5a5a;
    font-family: "Poppins", sans-serif; }

/*=====  End of feature icon  ======*/
/*=============================================
=            project gallery            =
=============================================*/
.project-gallery-wrapper {
  margin-bottom: -30px; }
  @media only screen and (max-width: 767px) {
    .project-gallery-wrapper .col-mobile-6 {
      flex: 0 0 50%;
      max-width: 50%; } }
  @media only screen and (max-width: 479px) {
    .project-gallery-wrapper .col-mobile-6 {
      flex: 0 0 100%;
      max-width: 100%; } }

.single-gallery-project {
  position: relative;
  margin-bottom: 30px; }
  .single-gallery-project:hover .single-gallery-project__content {
    visibility: visible;
    opacity: 1;
    bottom: 20px; }
  .single-gallery-project__image img {
    width: 100%; }
  .single-gallery-project__content {
    position: absolute;
    bottom: 0;
    left: 20px;
    padding: 20px;
    z-index: 1;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s;
    max-width: 220px; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .single-gallery-project__content {
        max-width: 170px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .single-gallery-project__content {
        visibility: visible;
        opacity: 1;
        bottom: 20px;
        left: 10px;
        max-width: 130px; } }
    @media only screen and (max-width: 767px) {
      .single-gallery-project__content {
        visibility: visible;
        opacity: 1;
        bottom: 20px; } }
    @media only screen and (max-width: 575px) {
      .single-gallery-project__content {
        visibility: visible;
        opacity: 1;
        left: 10px;
        max-width: 170px; } }
    @media only screen and (max-width: 479px) {
      .single-gallery-project__content {
        max-width: 300px; } }
    .single-gallery-project__content .title {
      font-size: 16px;
      line-height: 1.3;
      letter-spacing: 1px;
      color: #fff;
      margin-bottom: 0; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .single-gallery-project__content .title {
          font-size: 12px; } }
    .single-gallery-project__content a {
      font-size: 13px;
      line-height: 1.3;
      color: #ffffff;
      text-decoration: underline; }
    .single-gallery-project__content:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: "";
      background: #dc143c;
      opacity: 0.6;
      z-index: -1; }

/*=====  End of project gallery  ======*/
/*=============================================
=            blog            =
=============================================*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-section .sidebar {
    margin-top: 50px; } }

@media only screen and (max-width: 767px) {
  .blog-section .sidebar {
    margin-top: 30px; } }

.blog-grid-wrapper {
  margin-bottom: -40px; }

@media only screen and (max-width: 767px) {
  .blog-post-slider__area {
    margin-bottom: 40px; } }

.blog-post-slider__single-slide--grid-view {
  margin-bottom: 40px; }
  .blog-post-slider__single-slide--grid-view:hover .blog-post-slider__image a:before {
    background-color: rgba(0, 0, 0, 0.3); }

.blog-post-slider__image a {
  position: relative;
  display: block;
  width: 100%; }
  .blog-post-slider__image a:hover:before {
    background-color: rgba(0, 0, 0, 0.3); }
  .blog-post-slider__image a img {
    width: 100%; }
  .blog-post-slider__image a:before {
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    display: inline-block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent; }

.blog-post-slider__content {
  line-height: 1; }
  .blog-post-slider__content .post-meta {
    margin-bottom: 15px; }
  .blog-post-slider__content .post-date {
    margin-bottom: 25px;
    font-weight: 700;
    font-size: 13px;
    color: #b5b5b5;
    letter-spacing: 1px; }
  .blog-post-slider__content .post-title {
    font-size: 24px;
    margin-bottom: 20px; }
    .blog-post-slider__content .post-title a {
      color: #222;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
      .blog-post-slider__content .post-title a:hover {
        color: #dc143c; }
  .blog-post-slider__content .post-excerpt {
    font-family: "Poppins", sans-serif;
    line-height: 1.8;
    color: #5a5a5a; }
  .blog-post-slider__content .post-category {
    display: inline-block; }
    .blog-post-slider__content .post-category a {
      font-size: 13px;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      font-weight: 700;
      color: #dc143c;
      letter-spacing: 1px; }
      .blog-post-slider__content .post-category a:hover {
        color: #222; }

/*-- Blog --*/
/*-- Blog Details --*/
.blog-details .blog-inner .media {
  margin-bottom: 30px; }
  .blog-details .blog-inner .media .image {
    display: block; }
    .blog-details .blog-inner .media .image img {
      width: 100%; }

.blog-details .blog-inner .content .meta {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px; }
  .blog-details .blog-inner .content .meta li {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-transform: capitalize;
    margin-bottom: 5px; }
    .blog-details .blog-inner .content .meta li::after {
      content: "-";
      margin: 0 10px; }
    .blog-details .blog-inner .content .meta li:last-child::after {
      display: none; }
    .blog-details .blog-inner .content .meta li a {
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
      .blog-details .blog-inner .content .meta li a:hover {
        color: #dc143c; }

.blog-details .blog-inner .content .title {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 0; }
  @media only screen and (max-width: 767px) {
    .blog-details .blog-inner .content .title {
      font-size: 24px; } }
  @media only screen and (max-width: 479px) {
    .blog-details .blog-inner .content .title {
      font-size: 20px; } }
  .blog-details .blog-inner .content .title a {
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
    .blog-details .blog-inner .content .title a:hover {
      color: #dc143c; }

.blog-details .blog-inner .content .desc {
  margin-top: 20px; }
  .blog-details .blog-inner .content .desc p {
    font-family: "Poppins", sans-serif; }

.blog-details .blog-inner .content .tags {
  display: flex;
  flex-wrap: wrap;
  align-items: center; }
  .blog-details .blog-inner .content .tags li {
    display: flex;
    flex-wrap: wrap;
    line-height: 24px;
    margin-right: 15px; }
    .blog-details .blog-inner .content .tags li::after {
      content: ",";
      margin-left: 3px; }
    .blog-details .blog-inner .content .tags li:first-child::after, .blog-details .blog-inner .content .tags li:last-child::after {
      display: none; }
    .blog-details .blog-inner .content .tags li i {
      font-size: 18px;
      line-height: 24px; }
    .blog-details .blog-inner .content .tags li a {
      display: block;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
      .blog-details .blog-inner .content .tags li a:hover {
        color: #dc143c; }

.blog-gallery {
  position: relative; }
  .blog-gallery .ht-swiper-button-nav {
    width: 40px;
    height: 40px;
    line-height: 40px;
    background-color: #dc143c;
    border-color: #dc143c;
    color: #ffffff;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .blog-gallery .ht-swiper-button-prev {
    left: 30px; }
  .blog-gallery .ht-swiper-button-next {
    right: 30px; }
  .blog-gallery:hover .ht-swiper-button-nav {
    visibility: visible;
    opacity: 1; }
  .blog-gallery:hover .ht-swiper-button-prev {
    left: 20px; }
  .blog-gallery:hover .ht-swiper-button-next {
    right: 20px; }

/*-- Comment Wrap --*/
.comment-wrapper h3 {
  margin-bottom: 30px; }

/*-- Comment Form --*/
.comment-form input {
  width: 100%;
  height: 50px;
  border: 1px solid #eeeeee;
  padding: 5px 20px; }

.comment-form textarea {
  width: 100%;
  height: 120px;
  border: 1px solid #eeeeee;
  padding: 10px 20px;
  resize: none; }

.comment-form input[type="submit"], .comment-form button, .comment-form .submit {
  width: auto;
  height: 50px;
  border: none;
  padding: 5px 30px;
  background-color: #dc143c;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 700;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  margin-top: 15px; }
  .comment-form input[type="submit"]:hover, .comment-form button:hover, .comment-form .submit:hover {
    background-color: #1d1d25; }

blockquote.blockquote {
  background-color: #f1f2f3;
  padding: 30px;
  position: relative;
  z-index: 1;
  overflow: hidden; }

blockquote.blockquote::before {
  position: absolute;
  content: url(/assets/img/icons/quote-left.png);
  left: -5px;
  top: -10px;
  z-index: -1;
  opacity: 0.07; }

blockquote.blockquote p {
  font-size: 18px;
  font-style: italic; }

blockquote.blockquote .author {
  font-size: 14px;
  display: block;
  line-height: 18px; }

/*=====  End of blog  ======*/
/*=============================================
=            about            =
=============================================*/
.about-wrapper {
  margin-left: 0;
  margin-right: 0;
  align-items: center; }
  .about-wrapper > .col, .about-wrapper > [class*="col-"] {
    padding-left: 0;
    padding-right: 0; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-wrapper {
      align-items: inherit; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-wrapper {
      margin-left: -15px;
      margin-right: -15px; }
      .about-wrapper > .col, .about-wrapper > [class*="col-"] {
        padding-left: 15px;
        padding-right: 15px; } }
  @media only screen and (max-width: 767px) {
    .about-wrapper {
      margin-left: -15px;
      margin-right: -15px; }
      .about-wrapper > .col, .about-wrapper > [class*="col-"] {
        padding-left: 15px;
        padding-right: 15px; } }

/*-- About Image --*/
.about-image {
  position: relative;
  z-index: 1; }
  .about-image.about-image-1 {
    margin-bottom: -50px; }
  .about-image.about-image-2 {
    margin-top: -50px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-image {
      margin: 0 !important; } }
  @media only screen and (max-width: 767px) {
    .about-image {
      margin: 0 !important; } }
  @media only screen and (max-width: 575px) {
    .about-image.about-image-2 {
      margin-top: 30px !important; } }
  .about-image img {
    width: 100%; }

/*-- About Content --*/
.about-content.about-content-1 {
  margin-bottom: 50px;
  padding-right: 70px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-content.about-content-1 {
      padding-right: 40px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-content.about-content-1 {
      margin-top: 50px;
      padding-right: 0; } }
  @media only screen and (max-width: 767px) {
    .about-content.about-content-1 {
      margin-top: 30px;
      padding-right: 0; } }

.about-content.about-content-2 {
  margin-top: 70px;
  padding-left: 70px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-content.about-content-2 {
      padding-left: 40px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
    .about-content.about-content-2 {
      padding-left: 0; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-content.about-content-2 {
    margin-top: 80px; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-content.about-content-1 {
    margin-bottom: 0; }
  .about-content.about-content-2 {
    margin-top: 15px; } }

@media only screen and (max-width: 767px) {
  .about-content.about-content-1 {
    margin-bottom: 0; }
  .about-content.about-content-2 {
    margin-top: 15px; } }

.about-content h1 {
  font-weight: 400;
  position: relative;
  margin-bottom: 30px;
  font-size: 36px;
  display: flex;
  align-items: flex-end; }
  .about-content h1 span {
    font-size: 48px;
    line-height: 1;
    font-weight: 700;
    margin-right: 5px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-content h1 {
      font-size: 30px;
      margin-bottom: 20px; } }
  @media only screen and (max-width: 767px) {
    .about-content h1 {
      font-size: 30px;
      margin-bottom: 20px; } }
  @media only screen and (max-width: 479px) {
    .about-content h1 {
      font-size: 24px; }
      .about-content h1 span {
        font-size: 36px; } }

.about-content p {
  margin-bottom: 20px;
  font-family: "Poppins", sans-serif; }
  .about-content p:last-child {
    margin-bottom: 0; }

.about-content .btn {
  margin-top: 10px; }

/*-- About Image Two --*/
.about-image-two {
  position: relative; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-image-two {
      margin-bottom: 50px; } }
  @media only screen and (max-width: 767px) {
    .about-image-two {
      margin-bottom: 30px; } }
  .about-image-two img {
    width: 100%; }
  .about-image-two .video-popup a, .about-image-two .video-popup > button {
    position: absolute;
    z-index: 3;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
    width: 80px;
    height: 80px;
    text-align: center;
    background-color: #ffffff;
    border-color: transparent;
    color: #1d1d25;
    border-radius: 50%;
    padding-left: 5px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
    .about-image-two .video-popup a i, .about-image-two .video-popup > button i {
      font-size: 40px;
      line-height: 80px; }
    .about-image-two .video-popup a:hover, .about-image-two .video-popup > button:hover {
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
      -webkit-transform: translateX(-50%) translateY(-50%) scale(1.2);
              transform: translateX(-50%) translateY(-50%) scale(1.2); }

/*-- About Content Two --*/
.about-content-two h1 {
  font-weight: 600;
  margin-bottom: 15px;
  font-size: 36px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-content-two h1 {
      font-size: 30px; } }
  @media only screen and (max-width: 767px) {
    .about-content-two h1 {
      font-size: 30px; } }
  @media only screen and (max-width: 479px) {
    .about-content-two h1 {
      font-size: 24px; } }

.about-content-two h4 {
  line-height: 1.5;
  margin-bottom: 25px; }

.about-content-two p {
  margin-bottom: 20px;
  font-family: "Poppins", sans-serif; }
  .about-content-two p:last-child {
    margin-bottom: 0; }

.about-content-two .btn {
  margin-top: 10px; }

.feature-item-wrapper {
  margin-bottom: -30px; }

.feature {
  display: flex; }
  .feature .icon {
    min-width: 50px;
    width: 50px;
    margin-right: 20px; }
    .feature .icon img {
      width: 100%; }
  .feature .content h3 {
    font-size: 24px;
    font-weight: 600; }
  .feature .content p {
    max-width: 265px;
    font-family: "Poppins", sans-serif; }

/*=====  End of about  ======*/
/*=============================================
=            Service            =
=============================================*/
.service-item-wrapper {
  margin-bottom: -30px; }

/*-- Service --*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-details {
    margin-bottom: 50px; } }

@media only screen and (max-width: 767px) {
  .service-details {
    margin-bottom: 30px; } }

.service-details .content p {
  font-family: "Poppins", sans-serif; }

.service-gallery {
  position: relative; }
  .service-gallery .ht-swiper-button-nav {
    background-color: #dc143c;
    border-color: #dc143c;
    color: #ffffff;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .service-gallery .ht-swiper-button-prev {
    left: 70px; }
  .service-gallery .ht-swiper-button-next {
    right: 70px; }
  .service-gallery:hover .ht-swiper-button-nav {
    visibility: visible;
    opacity: 1; }
  .service-gallery:hover .ht-swiper-button-prev {
    left: 50px; }
    @media only screen and (max-width: 479px) {
      .service-gallery:hover .ht-swiper-button-prev {
        left: 15px; } }
  .service-gallery:hover .ht-swiper-button-next {
    right: 50px; }
    @media only screen and (max-width: 479px) {
      .service-gallery:hover .ht-swiper-button-next {
        right: 15px; } }

/*=====  End of Service  ======*/
/*=============================================
=            Project            =
=============================================*/
.project-item-wrapper {
  margin-bottom: -30px; }

/*-- Project --*/
.project-details p {
  font-family: "Poppins", sans-serif; }

/*-- Project Information --*/
.project-information {
  background-color: #1d1d25;
  padding: 30px 35px; }
  .project-information h3 {
    color: #ffffff;
    margin-bottom: 20px; }
  .project-information ul li {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    color: #ffffff;
    font-size: 14px; }
    .project-information ul li:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border: none; }
    .project-information ul li strong {
      width: 80px;
      display: inline-flex; }
    .project-information ul li a:hover {
      color: #dc143c; }

.gallery-item {
  position: relative;
  background: none;
  border: none;
  padding: 0; }
  .gallery-item::before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-color: #1d1d25;
    z-index: 1;
    opacity: 0;
    transition: all 0.3s ease 0s; }
  .gallery-item img {
    width: 100%; }
  .gallery-item .plus {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
    z-index: 3;
    opacity: 0; }
    .gallery-item .plus::before, .gallery-item .plus::after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translateX(-50%) translateY(-50%);
              transform: translateX(-50%) translateY(-50%);
      background-color: #ffffff;
      transition: all 0.3s ease 0s; }
    .gallery-item .plus::before {
      width: 150px;
      height: 1px; }
    .gallery-item .plus::after {
      width: 1px;
      height: 150px; }
  .gallery-item:hover::before {
    opacity: 0.75; }
  .gallery-item:hover .plus {
    opacity: 1; }
    .gallery-item:hover .plus::before {
      width: 40px; }
    .gallery-item:hover .plus::after {
      height: 40px; }

/*=====  End of Project  ======*/
/*=============================================
=            contact            =
=============================================*/
/*-- Map --*/
.contact-map iframe {
  height: 400px;
  width: 100%;
  border: none; }

/*-- Contact Information --*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-information {
    margin-bottom: 50px; } }

@media only screen and (max-width: 767px) {
  .contact-information {
    margin-bottom: 30px; } }

.contact-information h3 {
  margin-bottom: 30px; }

.contact-information ul li {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px; }
  .contact-information ul li:last-child {
    margin-bottom: 0; }
  .contact-information ul li .icon {
    width: 50px; }
    .contact-information ul li .icon i {
      font-size: 30px; }
  .contact-information ul li .text {
    max-width: calc(100% - 50px); }
    .contact-information ul li .text span, .contact-information ul li .text a {
      display: block;
      line-height: 24px;
      max-width: 230px; }
      .contact-information ul li .text span:hover, .contact-information ul li .text a:hover {
        color: #dc143c; }

/*-- Contact Form --*/
.contact-form h3 {
  margin-bottom: 30px; }

.contact-form input {
  width: 100%;
  height: 50px;
  border: 1px solid #eeeeee;
  padding: 5px 20px; }

.contact-form textarea {
  width: 100%;
  height: 120px;
  border: 1px solid #eeeeee;
  padding: 10px 20px;
  resize: none; }

.contact-form input[type="submit"], .contact-form button, .contact-form .submit {
  width: auto;
  height: 50px;
  border: none;
  padding: 5px 30px;
  background-color: #dc143c;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 700;
  margin-top: 15px; }
  .contact-form input[type="submit"]:hover, .contact-form button:hover, .contact-form .submit:hover {
    background-color: #1d1d25; }

.form-message {
  line-height: 2; }

/*=====  End of contact  ======*/
/*=============================================
=            404            =
=============================================*/
.error-page-wrapper {
  min-height: 700px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
    .error-page-wrapper {
      min-height: 500px; } }
  @media only screen and (max-width: 575px) {
    .error-page-wrapper {
      min-height: 400px; } }
  .error-page-wrapper [class*="col-"] {
    height: auto; }
    .error-page-wrapper [class*="col-"] * {
      height: auto; }

.error-page-content-wrap {
  height: auto;
  font-size: 1rem; }
  .error-page-content-wrap h2 {
    color: #222;
    font-size: 7.5rem;
    text-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    line-height: 1;
    font-weight: 900; }
    @media (max-width: 1200px) {
      .error-page-content-wrap h2 {
        font-size: calc(1.875rem + 7.5vw) ; } }
    @media only screen and (max-width: 767px) {
      .error-page-content-wrap h2 {
        font-size: 6.25rem; } }
  @media only screen and (max-width: 767px) and (max-width: 1200px) {
    .error-page-content-wrap h2 {
      font-size: calc(1.75rem + 6vw) ; } }
  .error-page-content-wrap h3 {
    font-size: 2rem;
    color: #000000;
    margin-bottom: 12px; }
    @media (max-width: 1200px) {
      .error-page-content-wrap h3 {
        font-size: calc(1.325rem + 0.9vw) ; } }
    @media only screen and (max-width: 767px) {
      .error-page-content-wrap h3 {
        font-size: 1.25rem;
        margin-bottom: 5px; } }

/*=====  End of 404  ======*/
/*=============================================
=            landing page            =
=============================================*/
.landing-page-wrapper {
  /*************************
    1.General
  *************************/
  /*-- Common Classes --*/
  /*-- Margin Top --*/
  /*-- Margin Bottom --*/
  /*-- Padding Top --*/
  /*-- Padding Bottom --*/
  /*-- Section --*/
  /*-- Background Color --*/
  /*-- Button --*/
  /*-- Button Animation --*/
  /*-- Button Hover --*/
  /*-- Button Hover Animation --*/
  /*-- Button White --*/
  /*-- Button black --*/
  /*-- Button Color --*/
  /*-- Section Title --*/
  /*-- Section Title 2 --*/
  /*-- Section Title 3 --*/
  /*-- Overlay --*/
  /*-- Page Banner Section --*/
  /*-- Pagination --*/
  /*-- Scroll Up --*/
  /*-- Main Wrapper --*/
  /* -----------------------------------
    02. Header Section
  --------------------------------------*/
  /*-- Logo --*/
  /*=============================================
  =            custom style            =
  =============================================*/
  /*=====  End of landing page  ======*/
  /*=============================================
  =            responsive            =
  =============================================*/
  /* Large Screen  */
  /* Laptop Screen  */
  /* desktop :992px. */
  /* Tablet :768px. */
  /* Large Mobile :480px. */
  /* small mobile :320px. */ }
  .landing-page-wrapper .landing-hero-bg {
    background-image: url("/assets/img/landing/hero-bg.jpg"); }
  .landing-page-wrapper .header-section .logo {
    padding-right: 0;
    flex-basis: auto; }
    @media only screen and (max-width: 479px) {
      .landing-page-wrapper .header-section .logo a img {
        max-width: 100%; } }
  .landing-page-wrapper .fix {
    overflow: hidden; }
  .landing-page-wrapper .float-left {
    float: left; }
  .landing-page-wrapper .float-right {
    float: right; }
  .landing-page-wrapper .mt-5 {
    margin-top: 5px; }
  .landing-page-wrapper .mt-10 {
    margin-top: 10px; }
  .landing-page-wrapper .mt-15 {
    margin-top: 15px; }
  .landing-page-wrapper .mt-20 {
    margin-top: 20px; }
  .landing-page-wrapper .mt-25 {
    margin-top: 25px; }
  .landing-page-wrapper .mt-30 {
    margin-top: 30px; }
  .landing-page-wrapper .mt-35 {
    margin-top: 35px; }
  .landing-page-wrapper .mt-40 {
    margin-top: 40px; }
  .landing-page-wrapper .mt-45 {
    margin-top: 45px; }
  .landing-page-wrapper .mt-50 {
    margin-top: 50px; }
  .landing-page-wrapper .mt-55 {
    margin-top: 55px; }
  .landing-page-wrapper .mt-60 {
    margin-top: 60px; }
  .landing-page-wrapper .mt-65 {
    margin-top: 65px; }
  .landing-page-wrapper .mt-70 {
    margin-top: 70px; }
  .landing-page-wrapper .mt-75 {
    margin-top: 75px; }
  .landing-page-wrapper .mt-80 {
    margin-top: 80px; }
  .landing-page-wrapper .mt-85 {
    margin-top: 85px; }
  .landing-page-wrapper .mt-90 {
    margin-top: 90px; }
  .landing-page-wrapper .mt-95 {
    margin-top: 95px; }
  .landing-page-wrapper .mt-100 {
    margin-top: 100px; }
  .landing-page-wrapper .mt-105 {
    margin-top: 105px; }
  .landing-page-wrapper .mt-110 {
    margin-top: 110px; }
  .landing-page-wrapper .mt-115 {
    margin-top: 115px; }
  .landing-page-wrapper .mt-120 {
    margin-top: 120px; }
  .landing-page-wrapper .mt-125 {
    margin-top: 125px; }
  .landing-page-wrapper .mt-130 {
    margin-top: 130px; }
  .landing-page-wrapper .mt-135 {
    margin-top: 135px; }
  .landing-page-wrapper .mt-140 {
    margin-top: 140px; }
  .landing-page-wrapper .mt-145 {
    margin-top: 145px; }
  .landing-page-wrapper .mt-150 {
    margin-top: 150px; }
  .landing-page-wrapper .mt-155 {
    margin-top: 155px; }
  .landing-page-wrapper .mt-160 {
    margin-top: 160px; }
  .landing-page-wrapper .mt-165 {
    margin-top: 165px; }
  .landing-page-wrapper .mt-170 {
    margin-top: 170px; }
  .landing-page-wrapper .mt-175 {
    margin-top: 175px; }
  .landing-page-wrapper .mt-180 {
    margin-top: 180px; }
  .landing-page-wrapper .mt-185 {
    margin-top: 185px; }
  .landing-page-wrapper .mt-190 {
    margin-top: 190px; }
  .landing-page-wrapper .mt-195 {
    margin-top: 195px; }
  .landing-page-wrapper .mt-200 {
    margin-top: 200px; }
  .landing-page-wrapper .mb-5 {
    margin-bottom: 5px; }
  .landing-page-wrapper .mb-10 {
    margin-bottom: 10px; }
  .landing-page-wrapper .mb-15 {
    margin-bottom: 15px; }
  .landing-page-wrapper .mb-20 {
    margin-bottom: 20px; }
  .landing-page-wrapper .mb-25 {
    margin-bottom: 25px; }
  .landing-page-wrapper .mb-30 {
    margin-bottom: 30px; }
  .landing-page-wrapper .mb-35 {
    margin-bottom: 35px; }
  .landing-page-wrapper .mb-40 {
    margin-bottom: 40px; }
  .landing-page-wrapper .mb-45 {
    margin-bottom: 45px; }
  .landing-page-wrapper .mb-50 {
    margin-bottom: 50px; }
  .landing-page-wrapper .mb-55 {
    margin-bottom: 55px; }
  .landing-page-wrapper .mb-60 {
    margin-bottom: 60px; }
  .landing-page-wrapper .mb-65 {
    margin-bottom: 65px; }
  .landing-page-wrapper .mb-70 {
    margin-bottom: 70px; }
  .landing-page-wrapper .mb-75 {
    margin-bottom: 75px; }
  .landing-page-wrapper .mb-80 {
    margin-bottom: 80px; }
  .landing-page-wrapper .mb-85 {
    margin-bottom: 85px; }
  .landing-page-wrapper .mb-90 {
    margin-bottom: 90px; }
  .landing-page-wrapper .mb-95 {
    margin-bottom: 95px; }
  .landing-page-wrapper .mb-100 {
    margin-bottom: 100px; }
  .landing-page-wrapper .mb-105 {
    margin-bottom: 105px; }
  .landing-page-wrapper .mb-110 {
    margin-bottom: 110px; }
  .landing-page-wrapper .mb-115 {
    margin-bottom: 115px; }
  .landing-page-wrapper .mb-120 {
    margin-bottom: 120px; }
  .landing-page-wrapper .mb-125 {
    margin-bottom: 125px; }
  .landing-page-wrapper .mb-130 {
    margin-bottom: 130px; }
  .landing-page-wrapper .mb-135 {
    margin-bottom: 135px; }
  .landing-page-wrapper .mb-140 {
    margin-bottom: 140px; }
  .landing-page-wrapper .mb-145 {
    margin-bottom: 145px; }
  .landing-page-wrapper .mb-150 {
    margin-bottom: 150px; }
  .landing-page-wrapper .mb-155 {
    margin-bottom: 155px; }
  .landing-page-wrapper .mb-160 {
    margin-bottom: 160px; }
  .landing-page-wrapper .mb-165 {
    margin-bottom: 165px; }
  .landing-page-wrapper .mb-170 {
    margin-bottom: 170px; }
  .landing-page-wrapper .mb-175 {
    margin-bottom: 175px; }
  .landing-page-wrapper .mb-180 {
    margin-bottom: 180px; }
  .landing-page-wrapper .mb-185 {
    margin-bottom: 185px; }
  .landing-page-wrapper .mb-190 {
    margin-bottom: 190px; }
  .landing-page-wrapper .mb-195 {
    margin-bottom: 195px; }
  .landing-page-wrapper .mb-200 {
    margin-bottom: 200px; }
  .landing-page-wrapper .pt-5 {
    padding-top: 5px; }
  .landing-page-wrapper .pt-10 {
    padding-top: 10px; }
  .landing-page-wrapper .pt-15 {
    padding-top: 15px; }
  .landing-page-wrapper .pt-20 {
    padding-top: 20px; }
  .landing-page-wrapper .pt-25 {
    padding-top: 25px; }
  .landing-page-wrapper .pt-30 {
    padding-top: 30px; }
  .landing-page-wrapper .pt-35 {
    padding-top: 35px; }
  .landing-page-wrapper .pt-40 {
    padding-top: 40px; }
  .landing-page-wrapper .pt-45 {
    padding-top: 45px; }
  .landing-page-wrapper .pt-50 {
    padding-top: 50px; }
  .landing-page-wrapper .pt-55 {
    padding-top: 55px; }
  .landing-page-wrapper .pt-60 {
    padding-top: 60px; }
  .landing-page-wrapper .pt-65 {
    padding-top: 65px; }
  .landing-page-wrapper .pt-70 {
    padding-top: 70px; }
  .landing-page-wrapper .pt-75 {
    padding-top: 75px; }
  .landing-page-wrapper .pt-80 {
    padding-top: 80px; }
  .landing-page-wrapper .pt-85 {
    padding-top: 85px; }
  .landing-page-wrapper .pt-90 {
    padding-top: 90px; }
  .landing-page-wrapper .pt-95 {
    padding-top: 95px; }
  .landing-page-wrapper .pt-100 {
    padding-top: 100px; }
  .landing-page-wrapper .pt-105 {
    padding-top: 105px; }
  .landing-page-wrapper .pt-110 {
    padding-top: 110px; }
  .landing-page-wrapper .pt-115 {
    padding-top: 115px; }
  .landing-page-wrapper .pt-120 {
    padding-top: 120px; }
  .landing-page-wrapper .pt-125 {
    padding-top: 125px; }
  .landing-page-wrapper .pt-130 {
    padding-top: 130px; }
  .landing-page-wrapper .pt-135 {
    padding-top: 135px; }
  .landing-page-wrapper .pt-140 {
    padding-top: 140px; }
  .landing-page-wrapper .pt-145 {
    padding-top: 145px; }
  .landing-page-wrapper .pt-150 {
    padding-top: 150px; }
  .landing-page-wrapper .pt-155 {
    padding-top: 155px; }
  .landing-page-wrapper .pt-160 {
    padding-top: 160px; }
  .landing-page-wrapper .pt-165 {
    padding-top: 165px; }
  .landing-page-wrapper .pt-170 {
    padding-top: 170px; }
  .landing-page-wrapper .pt-175 {
    padding-top: 175px; }
  .landing-page-wrapper .pt-180 {
    padding-top: 180px; }
  .landing-page-wrapper .pt-185 {
    padding-top: 185px; }
  .landing-page-wrapper .pt-190 {
    padding-top: 190px; }
  .landing-page-wrapper .pt-195 {
    padding-top: 195px; }
  .landing-page-wrapper .pt-200 {
    padding-top: 200px; }
  .landing-page-wrapper .pb-5 {
    padding-bottom: 5px; }
  .landing-page-wrapper .pb-10 {
    padding-bottom: 10px; }
  .landing-page-wrapper .pb-15 {
    padding-bottom: 15px; }
  .landing-page-wrapper .pb-20 {
    padding-bottom: 20px; }
  .landing-page-wrapper .pb-25 {
    padding-bottom: 25px; }
  .landing-page-wrapper .pb-30 {
    padding-bottom: 30px; }
  .landing-page-wrapper .pb-35 {
    padding-bottom: 35px; }
  .landing-page-wrapper .pb-40 {
    padding-bottom: 40px; }
  .landing-page-wrapper .pb-45 {
    padding-bottom: 45px; }
  .landing-page-wrapper .pb-50 {
    padding-bottom: 50px; }
  .landing-page-wrapper .pb-55 {
    padding-bottom: 55px; }
  .landing-page-wrapper .pb-60 {
    padding-bottom: 60px; }
  .landing-page-wrapper .pb-65 {
    padding-bottom: 65px; }
  .landing-page-wrapper .pb-70 {
    padding-bottom: 70px; }
  .landing-page-wrapper .pb-75 {
    padding-bottom: 75px; }
  .landing-page-wrapper .pb-80 {
    padding-bottom: 80px; }
  .landing-page-wrapper .pb-85 {
    padding-bottom: 85px; }
  .landing-page-wrapper .pb-90 {
    padding-bottom: 90px; }
  .landing-page-wrapper .pb-95 {
    padding-bottom: 95px; }
  .landing-page-wrapper .pb-100 {
    padding-bottom: 100px; }
  .landing-page-wrapper .pb-105 {
    padding-bottom: 105px; }
  .landing-page-wrapper .pb-110 {
    padding-bottom: 110px; }
  .landing-page-wrapper .pb-115 {
    padding-bottom: 115px; }
  .landing-page-wrapper .pb-120 {
    padding-bottom: 120px; }
  .landing-page-wrapper .pb-125 {
    padding-bottom: 125px; }
  .landing-page-wrapper .pb-130 {
    padding-bottom: 130px; }
  .landing-page-wrapper .pb-135 {
    padding-bottom: 135px; }
  .landing-page-wrapper .pb-140 {
    padding-bottom: 140px; }
  .landing-page-wrapper .pb-145 {
    padding-bottom: 145px; }
  .landing-page-wrapper .pb-150 {
    padding-bottom: 150px; }
  .landing-page-wrapper .pb-155 {
    padding-bottom: 155px; }
  .landing-page-wrapper .pb-160 {
    padding-bottom: 160px; }
  .landing-page-wrapper .pb-165 {
    padding-bottom: 165px; }
  .landing-page-wrapper .pb-170 {
    padding-bottom: 170px; }
  .landing-page-wrapper .pb-175 {
    padding-bottom: 175px; }
  .landing-page-wrapper .pb-180 {
    padding-bottom: 180px; }
  .landing-page-wrapper .pb-185 {
    padding-bottom: 185px; }
  .landing-page-wrapper .pb-190 {
    padding-bottom: 190px; }
  .landing-page-wrapper .pb-195 {
    padding-bottom: 195px; }
  .landing-page-wrapper .pb-200 {
    padding-bottom: 200px; }
  .landing-page-wrapper .section {
    float: left;
    position: relative;
    width: 100%; }
  .landing-page-wrapper .bg-gray {
    background-color: #f6f6f6; }
  .landing-page-wrapper .bg-dark {
    background-color: #1a1a1a; }
  .landing-page-wrapper .bg-light-dark {
    background-color: #232323; }
  .landing-page-wrapper .btn {
    background-color: transparent;
    border-radius: 0;
    color: inherit;
    font-size: 12px;
    font-weight: 700;
    height: 43px;
    letter-spacing: 0.4px;
    line-height: 23px;
    overflow: hidden;
    padding: 9px 50px;
    position: relative;
    text-transform: uppercase;
    z-index: 1; }
  .landing-page-wrapper .btn::before {
    -webkit-animation: 0.3s linear 0s normal forwards 1 running btnAnimationOut;
    animation: 0.3s linear 0s normal forwards 1 running btnAnimationOut;
    background-color: inherit;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    -webkit-transform: scaleY(0.02);
    transform: scaleY(0.02);
    width: 21px;
    z-index: -1; }

@-webkit-keyframes btnAnimationOut {
  0% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    width: 100%; }
  50% {
    -webkit-transform: scaleY(0.02);
    transform: scaleY(0.02);
    width: 100%; }
  100% {
    -webkit-transform: scaleY(0.02);
    transform: scaleY(0.02); } }

@keyframes btnAnimationOut {
  0% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    width: 100%; }
  50% {
    -webkit-transform: scaleY(0.02);
    transform: scaleY(0.02);
    width: 100%; }
  100% {
    -webkit-transform: scaleY(0.02);
    transform: scaleY(0.02); } }
  .landing-page-wrapper .btn:hover {
    transition: all 0.3s ease 0.3s; }
  .landing-page-wrapper .btn:hover::before {
    -webkit-animation: 0.5s linear 0s normal forwards 1 running btnAnimationIn;
    animation: 0.5s linear 0s normal forwards 1 running btnAnimationIn; }

@-webkit-keyframes btnAnimationIn {
  0% {
    -webkit-transform: scaleY(0.02);
    transform: scaleY(0.02); }
  50% {
    -webkit-transform: scaleY(0.02);
    transform: scaleY(0.02);
    width: 100%; }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    width: 100%; } }

@keyframes btnAnimationIn {
  0% {
    -webkit-transform: scaleY(0.02);
    transform: scaleY(0.02); }
  50% {
    -webkit-transform: scaleY(0.02);
    transform: scaleY(0.02);
    width: 100%; }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    width: 100%; } }
  .landing-page-wrapper .btn.white {
    border: 1px solid #fff;
    color: #fff; }
  .landing-page-wrapper .btn.white:hover {
    color: #0056ff; }
  .landing-page-wrapper .btn.white::before {
    background-color: #fff; }
  .landing-page-wrapper .btn.black {
    border: 1px solid #232323;
    color: #232323; }
  .landing-page-wrapper .btn.black:hover {
    color: #ffffff; }
  .landing-page-wrapper .btn.black::before {
    background-color: #232323; }
  .landing-page-wrapper .btn.color {
    border: 1px solid #0056ff;
    color: #0056ff; }
  .landing-page-wrapper .btn.color:hover {
    color: #ffffff; }
  .landing-page-wrapper .btn.color::before {
    background-color: #0056ff; }
  .landing-page-wrapper .section-title h1 {
    color: #373737;
    display: block;
    font-weight: 500;
    line-height: 28px;
    margin: 0;
    position: relative;
    text-transform: capitalize;
    z-index: 1; }
  .landing-page-wrapper .section-title.white h1 {
    color: #ffffff; }
  .landing-page-wrapper .section-title h1 span {
    color: #0056ff; }
  .landing-page-wrapper .section-title p {
    color: #5b5b5b;
    display: inline-block;
    font-family: "Montserrat",sans-serif;
    font-size: 18px;
    font-weight: 300;
    line-height: 30px;
    margin-bottom: 0;
    margin-top: 30px;
    max-width: 775px; }
  .landing-page-wrapper .section-title.white p {
    color: #ffffff; }
  .landing-page-wrapper .section-title-2 {
    z-index: 1; }
  .landing-page-wrapper .section-title-2::before {
    color: #2b2b2b;
    content: attr(data-title);
    font-family: "Montserrat",sans-serif;
    font-size: 150px;
    font-weight: 800;
    left: 15px;
    line-height: 110px;
    position: absolute;
    right: 15px;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    text-transform: uppercase;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: -1; }
  .landing-page-wrapper .section-title-2 h1 {
    color: #ffffff;
    display: block;
    font-size: 40px;
    font-weight: 800;
    line-height: 28px;
    margin: 0;
    position: relative;
    text-transform: uppercase;
    z-index: 1; }
  .landing-page-wrapper .section-title-2 p {
    color: #d7d7d7;
    display: inline-block;
    font-family: "Montserrat",sans-serif;
    font-size: 18px;
    font-weight: 300;
    line-height: 30px;
    margin-bottom: 0;
    margin-top: 33px;
    max-width: 775px; }
  .landing-page-wrapper .section-title-3 {
    z-index: 1; }
  .landing-page-wrapper .section-title-3 h1 {
    color: #373737;
    display: block;
    font-size: 32px;
    font-weight: 800;
    line-height: 23px;
    margin: 0;
    position: relative;
    text-transform: uppercase;
    z-index: 1; }
  .landing-page-wrapper .section-title-3.white h1 {
    color: #ffffff; }
  .landing-page-wrapper .section-title-3 h1 span {
    color: #0056ff; }
  .landing-page-wrapper .section-title-3 p {
    color: #6b6b6b;
    display: inline-block;
    font-family: "Montserrat",sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 25px;
    margin-bottom: 0;
    margin-top: 30px;
    max-width: 520px; }
  .landing-page-wrapper .section-title-3.white p {
    color: #ffffff; }
  .landing-page-wrapper .overlay {
    background-attachment: fixed;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index: 1; }
  .landing-page-wrapper .overlay::before {
    background-color: #000;
    bottom: 0;
    content: "";
    left: 0;
    opacity: 0.7;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1; }
  .landing-page-wrapper .overlay-dark::before {
    background-color: #000000;
    opacity: 0.9; }
  .landing-page-wrapper .overlay-gradient::before {
    background: #2d3e50;
    background: -webkit-gradient(left top, left bottom, color-stop(0%, #2d3e50), color-stop(100%, #ed562d));
    background: -webkit-gradient(left top, left bottom, color-stop(0%, #2d3e50), color-stop(100%, #ed562d));
    background: linear-gradient(to bottom, #2d3e50 0%, #ed562d 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2d3e50', endColorstr='#ed562d', GradientType=0 );
    opacity: 0.9; }
  .landing-page-wrapper .page-banner-section {
    padding: 250px 0; }
  .landing-page-wrapper .page-banner-content h1 {
    color: #ffffff;
    font-size: 40px;
    font-weight: 500;
    margin: 0;
    text-transform: capitalize; }
  .landing-page-wrapper .pagination {
    display: block;
    margin: 20px 0 0;
    text-align: center; }
  .landing-page-wrapper .pagination ul {
    display: inline-block;
    vertical-align: top; }
  .landing-page-wrapper .pagination ul li {
    display: block;
    float: left;
    margin: 0 5px; }
  .landing-page-wrapper .pagination ul li a {
    border: 1px solid #acacac;
    border-radius: 50%;
    color: #6d6d6d;
    display: block;
    font-size: 12px;
    font-weight: 300;
    height: 30px;
    line-height: 30px;
    text-align: center;
    width: 30px; }
  .landing-page-wrapper .pagination ul li a i {
    display: block;
    font-size: 18px;
    line-height: 30px; }
  .landing-page-wrapper .pagination ul li a:hover, .landing-page-wrapper .pagination ul li.active a {
    border: 1px solid #0056ff;
    color: #0056ff; }
  .landing-page-wrapper #scrollUp {
    background-color: transparent;
    border: 2px solid #999;
    border-radius: 50%;
    bottom: 28px;
    color: #999;
    height: 40px;
    position: fixed;
    right: 30px;
    text-align: center;
    width: 40px; }
  .landing-page-wrapper #scrollUp i {
    display: block;
    font-size: 24px;
    line-height: 35px; }
  .landing-page-wrapper #scrollUp:hover {
    background-color: #3f3f3f;
    color: #fff; }
  .landing-page-wrapper .main-wrapper {
    background-color: #fff;
    margin-bottom: 278px;
    z-index: 9; }
  .landing-page-wrapper .header-section {
    background-color: transparent;
    left: 0;
    position: absolute;
    right: 0;
    padding: 20px 0;
    top: 0;
    z-index: 999; }
  .landing-page-wrapper .header-section.stick {
    -webkit-animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
    animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
    background-color: #ffffff;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
    position: fixed;
    top: 0; }
  .landing-page-wrapper .logo a .sticky-logo {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    opacity: 0;
    padding-left: 15px; }
  .landing-page-wrapper .stick .logo a img {
    opacity: 0; }
  .landing-page-wrapper .stick .logo a .sticky-logo {
    opacity: 1; }
  .landing-page-wrapper .buy-btn {
    border: 2px solid #ffffff;
    border-radius: 50px;
    color: #ffffff !important;
    display: inline-block;
    font-size: 13px;
    font-weight: 500;
    height: 40px;
    line-height: 24px;
    margin-top: 0;
    padding: 6px 35px;
    text-transform: uppercase; }
  .landing-page-wrapper .stick .buy-btn {
    border: 2px solid #0056ff;
    color: #0056ff;
    margin-top: 3px; }
  .landing-page-wrapper .buy-btn:hover {
    background-color: #0056ff;
    border-color: #0056ff;
    color: #ffffff !important; }
  .landing-page-wrapper .hero-section {
    padding: 300px 0 250px; }
  .landing-page-wrapper .hero-content img {
    margin-bottom: 30px;
    width: 170px; }
  .landing-page-wrapper .hero-content h1 {
    color: #fff;
    font-weight: 500; }
  .landing-page-wrapper .hero-content h1 strong {
    font-size: 50px;
    font-weight: 700;
    margin-bottom: 30px;
    display: block; }
  .landing-page-wrapper .hero-content h1 span {
    display: block;
    font-size: 44px;
    text-transform: uppercase;
    margin-bottom: 20px;
    font-weight: 700; }
  .landing-page-wrapper .hero-content p {
    color: #fff;
    margin: 20px auto 0;
    max-width: 650px; }
  .landing-page-wrapper .hero-content a {
    margin-top: 40px;
    display: inline-block; }
  .landing-page-wrapper .demo-item {
    text-align: center; }
  .landing-page-wrapper .demo-item .image {
    display: block;
    position: relative;
    transition: all 0.3s ease 0s; }
  .landing-page-wrapper .demo-item .image::after {
    background-color: #0056ff;
    content: "";
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%; }
  .landing-page-wrapper .demo-item:hover .image {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px); }
  .landing-page-wrapper .demo-item:hover .image::after {
    opacity: 0.8; }
  .landing-page-wrapper .demo-item .image i {
    border: 2px solid #fff;
    border-radius: 50%;
    color: #fff;
    font-size: 20px;
    height: 60px;
    left: 50%;
    line-height: 56px;
    margin-left: -30px;
    opacity: 0;
    position: absolute;
    top: 50%;
    transition: all 0.3s ease 0s;
    width: 60px;
    z-index: 9; }
  .landing-page-wrapper .demo-item:hover .image i {
    opacity: 1;
    margin-top: -30px; }
  .landing-page-wrapper .demo-item .image i:hover {
    border: 2px solid #ffffff;
    background-color: #ffffff;
    color: #0056ff; }
  .landing-page-wrapper .demo-item .image img {
    width: 100%; }
  .landing-page-wrapper .demo-item .title {
    font-size: 18px;
    font-weight: 400;
    margin: 30px 0 0;
    text-transform: uppercase; }
  .landing-page-wrapper .demo-item .title a {
    color: #3e3e3e;
    display: block;
    padding: 5px; }
  .landing-page-wrapper .demo-item .title a:hover {
    color: #0056ff; }
  .landing-page-wrapper .single-feature .icon {
    color: #0056ff;
    margin-right: 20px; }
  .landing-page-wrapper .single-feature .icon i {
    display: block;
    font-size: 36px; }
  .landing-page-wrapper .single-feature .content h4 {
    display: block;
    font-size: 18px;
    line-height: 13px;
    padding-bottom: 2px;
    text-transform: capitalize; }
  .landing-page-wrapper .single-feature .content p {
    color: #666;
    margin: 0; }
  .landing-page-wrapper .footer-section {
    background-color: #0056ff; }
  .landing-page-wrapper .footer-section h1 {
    color: #fff;
    display: block;
    float: left;
    font-size: 30px;
    margin-bottom: 20px; }
  .landing-page-wrapper .footer-section .buy-btn:hover {
    color: #0056ff;
    border-color: #ffffff;
    background-color: #ffffff; }
  .landing-page-wrapper .also-like .demo-item .title {
    font-size: 16px;
    font-weight: 400;
    margin: 22px 0 0;
    text-transform: capitalize; }
  .landing-page-wrapper .buy-btn:hover {
    background-color: #f0542d;
    border-color: #f0542d;
    color: #ffffff; }
  .landing-page-wrapper .header-section .buy-btn {
    border-color: #ffffff;
    background-color: #ffffff;
    color: #000000 !important;
    font-weight: 600; }
  .landing-page-wrapper .header-section .buy-btn:hover {
    border-color: #f0542d;
    background-color: #f0542d;
    color: #ffffff !important; }
  .landing-page-wrapper .stick.header-section .buy-btn {
    border-color: #000000;
    background-color: #000000;
    color: #ffffff !important; }
  .landing-page-wrapper .stick.header-section .buy-btn:hover {
    border-color: #f0542d;
    background-color: #f0542d;
    color: #ffffff !important; }
  .landing-page-wrapper .demo-item .image img {
    border: 1px solid #f1f1f1; }
  .landing-page-wrapper .demo-item:hover .image {
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15); }
  .landing-page-wrapper .demo-item .image::after {
    display: none; }
  .landing-page-wrapper .demo-item .image i {
    display: none; }
  .landing-page-wrapper .stick .buy-btn {
    border: 2px solid #f0542d;
    color: #f0542d; }
  .landing-page-wrapper .single-feature .icon {
    color: #f0542d;
    width: 50px; }
  .landing-page-wrapper .single-feature .content h4 {
    line-height: 1; }
  .landing-page-wrapper .footer-section {
    background-color: #444; }
  .landing-page-wrapper .stick .buy-btn:hover {
    color: #fff; }
  .landing-page-wrapper .demo-item .title a:hover {
    color: #f0542d; }
  .landing-page-wrapper .demo-item .title {
    text-transform: capitalize;
    font-weight: 500;
    margin-top: 20px; }
  .landing-page-wrapper .demo-item .title span {
    font-size: 13px; }
  .landing-page-wrapper .footer-section .buy-btn:hover {
    background-color: #f0542d;
    border-color: #f0542d;
    color: #ffffff; }
  .landing-page-wrapper .hero-content h1 {
    text-transform: none; }
  .landing-page-wrapper .hero-content h1 strong {
    text-transform: capitalize; }
  .landing-page-wrapper .logo a, .landing-page-wrapper .stick .logo a {
    width: auto; }
  @media only screen and (max-width: 767px) {
    .landing-page-wrapper .footer-section .buy-btn {
      float: left !important; } }
  @media only screen and (min-width: 992px) and (max-width: 1169px) {
    .landing-page-wrapper .single-feature .content p {
      font-size: 14px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .landing-page-wrapper .hero-content h1 {
      font-size: 30px; }
    .landing-page-wrapper .demo-content h1 {
      font-size: 30px; }
    .landing-page-wrapper .demo-item .image i {
      height: 50px;
      line-height: 46px;
      width: 50px; }
    .landing-page-wrapper .single-feature .content p {
      font-size: 14px; }
    .landing-page-wrapper .footer-section h1 {
      font-size: 24px; } }
  @media only screen and (max-width: 767px) {
    .landing-page-wrapper .hero-section {
      padding: 150px 0 100px; }
    .landing-page-wrapper .hero-content h1 {
      font-size: 24px; }
    .landing-page-wrapper .hero-content h1 strong {
      font-size: 40px;
      margin-bottom: 15px; }
    .landing-page-wrapper .hero-content a {
      margin-top: 20px; }
    .landing-page-wrapper .demo-content h1 {
      font-size: 30px; }
    .landing-page-wrapper .section-title h1 {
      font-size: 30px; }
    .landing-page-wrapper .footer-section .buy-btn {
      float: left; } }
  @media only screen and (max-width: 479px) {
    .landing-page-wrapper .buy-btn {
      font-size: 11px;
      height: 34px;
      padding: 3px 25px;
      margin-top: 0; }
    .landing-page-wrapper .hero-section {
      padding: 150px 0 100px; }
    .landing-page-wrapper .hero-content h1 {
      font-size: 14px;
      line-height: 20px; }
    .landing-page-wrapper .hero-content h1 strong {
      font-size: 30px; }
    .landing-page-wrapper .hero-content p {
      font-size: 13px; }
    .landing-page-wrapper .hero-content a {
      margin-top: 20px; } }

/*=====  End of responsive  ======*/

